import React, { useState, useEffect } from 'react'
import { lookupUser, updateUser } from '../../api/auth.js';
import { useAuth } from '../../context/AuthContext.jsx';
import { useForm } from 'react-hook-form';
import { Box, Divider } from '@mui/material';
import { colors } from "../../assets/styles/theme.ts";
import {
  Styled,
  BoxMiCuenta,
  Title,
  Description,
  Header,
  BoxImg,
  Body,
  BoxDataUsuario
} from './MiCuentaStyled';
import {
  EditOutlined,
  AccountCircleOutlined,
  AlternateEmailOutlined,
  LockResetOutlined,
  VisibilityOff,
  Visibility
} from '@mui/icons-material';
import {
  IconButton,
  TextField,
  InputAdornment,
  CircularProgress,
  Typography
} from '@mui/material';
import { loginUser } from "../../api/auth.js";
import CustomButton from '../../components/Button/CustomButton.tsx';
import RenderImg from '../../components/RenderImg/RenderImg.tsx';
import LimsOil from "../../assets/Login/LimsOil.png";
import Toast from "../../components/Toast/Toast.jsx";


const MiCuenta = () => {
  const { token } = useAuth();
  const { register, handleSubmit, setValue, reset, watch } = useForm();
  const [editState, setEditState] = useState({});
  const [showPasswordIndex, setShowPasswordIndex] = useState([]);
  const [preview, setPreview] = useState(null);
  const [user, setUser] = useState({});
  const tokenId = token ? token.id : "";
  const [inputValues, setInputValues] = useState(["", "", "", ""]);
  const [statusPw, setStatusPw] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [toastError, setToastError] = useState(null);
  const [msj, setMsj] = useState([])
  const [stateCancelar, setStateCancelar] = useState(true)
  let changeMessages = [];
  const currentPassword = watch("passwordActual");

  const toggleEdit = (index, name) => {
    setEditState((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
    if (!editState[index]) {
      if (name === 'fullName') {
        setValue('fullName', `${user?.firstName ?? ''} ${user?.lastName ?? ''}`);
      } else if (name === 'email') {
        setValue('email', user?.email ?? '');
      }
    }
  };
  const handleInputChange = (index, value) => {
    const updatedInputValues = [...inputValues];
    updatedInputValues[index] = value;
    setInputValues(updatedInputValues);
    if (value === '') {
      setStatusPw(null);
    }
  };

  const fetchUser = async () => {
    try {
      const res = await lookupUser(tokenId);
      setUser(res?.data ?? {});
    } catch (err) {
      console.error(err);
    }
  };
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    const fileSizeInBytes = file.size;
    const fileSizeInKB = fileSizeInBytes / 1024;
    const fileSizeInMB = fileSizeInKB / 1024;

    if (fileSizeInMB <= 50) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result.split(",")[1];
        setPreview(URL.createObjectURL(file));
        setValue("img", base64String);
      };
      reader.readAsDataURL(file);
    }
  };
  const handlePasswordActual = async (pw) => {
    setIsLoading(true);
    try {
      await loginUser({
        login: user?.login ?? "",
        password: pw ?? ""
      });

      setStatusPw(true);
      setIsLoading(false);
      setToastError(false);
      return true;
    } catch (err) {
      changeMessages.push('Error en la validación de la contraseña actual');
      setStatusPw(false);
      setIsLoading(false);
      setToastError(true);
      return false;
    }
  };

  const onSubmit = async (value) => {
    setIsLoading(true);
    const isPasswordValid = await handlePasswordActual(value?.passwordActual);
    const [firstName, ...lastName] = value?.fullName?.split(" ") || [];

    let json = {
      firstName: firstName ?? user?.firstName,
      lastName: lastName.join(" ") || user?.lastName,
      email: value?.email ?? user?.email,
      passwordHash: value?.password,
      id: tokenId,
      login: token?.login,
      createdDate: new Date().toISOString(),
      activated: true,
      rol: user?.rol,
      imageUrl: value?.img,
      tenantId: 1,
      createdBy: "admin",
      status: "ACTIVE",
      mostrar: "SI",
    };

    if (!json.passwordHash) delete json.passwordHash;
    if (!json.imageUrl || json.imageUrl.length === 0) delete json.imageUrl;

    if (isPasswordValid) {
      try {
        setIsLoading(true);
        await updateUser(json).then(() => {
          let hasChanges = false;
          
          if (value?.img?.length > 0) {
            changeMessages.push("Imagen actualizada con éxito");
            hasChanges = true;
            
          }
          if (json.firstName !== user?.firstName || json.lastName !== user?.lastName) {
            changeMessages.push("Nombre y apellido actualizados con éxito");
            hasChanges = true;
          }
          if (value?.email && value.email !== user?.email) {
            changeMessages.push("Email actualizado con éxito");
            hasChanges = true;
          }
          if (value?.password) {
            changeMessages.push("Contraseña actualizada con éxito");
            hasChanges = true;
          }

          if (hasChanges) {
            setIsLoading(false);
            setTimeout(() => {
              window.location.reload();
            }, 2500);
          } else {
            setIsLoading(false);
          }
        });
        await fetchUser();
      } catch (err) {
        
        changeMessages.push("Error al actualizar los datos");
        setToastError(true);
      } finally {
        setTimeout(() => {
          setIsLoading(false);
          setMsj([]);
        }, 1500)
      }
    }
    setMsj(prevMsj => [...prevMsj, ...changeMessages]);
  };
  const handleTogglePasswordVisibility = (index) => {
    setShowPasswordIndex((prev) => {
      if (prev.includes(index)) {
        return prev.filter((i) => i !== index);
      } else {
        return [...prev, index];
      }
    });
  };
  useEffect(() => {
    let isMounted = true;
    const fetchUser = async () => {
      try {
        const res = await lookupUser(tokenId);
        if (isMounted) {
          setUser(res?.data ?? {});
        }
      } catch (err) {
        console.log(err);
      }
    };
    fetchUser();
    return () => {
      isMounted = false;
    };
  }, [tokenId]);

  const renderData = () => {
    const data = [
      {
        title: "Nombre y Apellido",
        icon: <AccountCircleOutlined fontSize='medium' />,
        name: "fullName",
        type: "text",
        placeholder: `${user?.firstName ?? ''} ${user?.lastName ?? ''}`
      },
      {
        title: "Email",
        icon: <AlternateEmailOutlined fontSize='medium' />,
        name: "email",
        type: "email",
        placeholder: user?.email ?? ''
      },
      {
        title: "Contraseña actual",
        icon: <LockResetOutlined fontSize='medium' />,
        name: "passwordActual",
        type: "password",
        placeholder: "**********"
      },
      {
        title: "Nueva contraseña",
        icon: <LockResetOutlined fontSize='medium' />,
        name: "password",
        type: "password",
        placeholder: "**********"
      }
    ];

    return data.map((res, i) => (
      <BoxDataUsuario
        key={i}
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          padding: "20px"
        }}
      >
        {(i === 2 && !currentPassword) && (
          <Typography variant="body1">Por favor, ingresa tu contraseña actual antes de continuar.</Typography>
        )}
        <BoxDataUsuario>
          <Title sx={{ fontSize: "14px", fontWeight: "700", display: "flex", gap: "10px" }}>
            <span>{res.icon}</span>
            {res.title}
          </Title>
          {editState[i] ? (
            <>
              <Box sx={{ display: "flex", alignItems: "center" }}>

                <TextField
                  type={res.type === "password" && showPasswordIndex.includes(i) ? "text" : res.type}
                  variant="standard"
                  {...register(res.name, {
                    required: res.name === 'passwordActual',
                    onChange: (e) => {
                      if (res.name === 'fullName') {
                        const [firstName, ...lastName] = e.target.value.split(" ");
                        setValue("firstName", firstName);
                        setValue("lastName", lastName.join(" "));
                        handleInputChange(i, e.target.value);
                        
                      }
                      else {
                        setValue(res.name, e.target.value);
                        setValue("firstName", user.firstName ?? '');
                        setValue("lastName", user.lastName ?? '');
                        setStateCancelar(false);
                      }
                    },
                  })}
                  className="input"
                  size="small"
                  placeholder={res.placeholder}
                  disabled={res.name !== "passwordActual" && !currentPassword}
                  error={inputValues[2] && statusPw === false && res.name === "passwordActual" ?
                    true
                    : false}
                  helperText={inputValues[2]?.length > 0 && statusPw === false && res.name === "passwordActual" ?
                    "Contraseña actual incorrecta"
                    : null}
                  InputProps={{
                    endAdornment: res.type === "password" && (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => handleTogglePasswordVisibility(i)}
                          edge="end"
                        >
                          {showPasswordIndex.includes(i) ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <IconButton
                  onClick={() => toggleEdit(i, res.name)}
                  disabled={res.name !== "passwordActual" && !currentPassword}>
                  <EditOutlined sx={{
                    color: res.name !== "passwordActual" && !currentPassword ? colors.disabledColor : colors.turquoise,
                    cursor: (res.name !== "passwordActual" && !currentPassword) ? "not-allowed" : "pointer" // Cambia el cursor dependiendo del estado
                  }} />
                </IconButton>
              </Box>

            </>
          ) : (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Description>{inputValues[i] || res.placeholder}</Description>
              <IconButton style={{ cursor: "pointer" }}
                disabled={res.name !== "passwordActual" && !currentPassword}
                onClick={() => toggleEdit(i, res.name)}>
                <EditOutlined sx={{
                  color: res.name !== "passwordActual" && !currentPassword ? colors.disabledColor : colors.turquoise,
                  cursor: (res.name !== "passwordActual" && !currentPassword) ? "not-allowed" : "pointer"
                }} />
              </IconButton>
            </Box>
          )}
        </BoxDataUsuario>

        <Divider sx={{ color: colors.turquoise }} />
      </BoxDataUsuario>
    ));
  };
  return (
    <Styled>
      {isLoading && (
        <CircularProgress size={50}
          sx={{
            position: "fixed",
            bottom: "10",
            padding: "10px",
            zIndex: "9999",
            left: "0",
          }}
        />
      )}

      {msj.length > 0 ? msj?.map((message, index) => (
        <Toast
          index={index}
          title={message}
          type={toastError ? "error" : "success"}
          close={() => setMsj(prev => prev.filter((_, i) => i !== index))}
        />
      )) : null}

      <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
        <BoxMiCuenta>
          <Header>
            <Title sx={{ fontSize: "24px", fontWeight: "600" }}>
              Mi Cuenta
            </Title>

            <Box sx={{ display: "flex", gap: "5px", alignItems: "flex-end" }}>
              <BoxImg>
                {preview ? (
                  <img
                    src={preview}
                    alt="Preview"
                    className="img"
                    style={{ width: '100%', objectFit: 'cover', height: '100%' }} />
                ) : user?.imageUrl ? (
                  <img
                    src={`data:image/png;base64,${user.imageUrl}`}
                    alt="Profile" className="img"
                    style={{ width: '100%', objectFit: 'cover', height: '100%' }} />
                ) : (
                  <RenderImg img={LimsOil} className="img" />
                )}
              </BoxImg>
              <IconButton component="label" disabled={!currentPassword}>
                <EditOutlined sx={{
                  color: !currentPassword ? colors.disabledColor : colors.turquoise
                }}
                  fontSize='large' />
                <input
                  type="file"
                  hidden
                  accept="image/*"
                  {...register("img", {
                    onChange: (e) => {
                      const file = e.target.files[0];
                      handleImageChange(file);
                    },
                  })}
                  onChange={handleImageChange}
                />
              </IconButton>
            </Box>


          </Header>
                  
          <Body>
            {renderData()}
            <Box sx={{ width: "100%", margin: "auto", padding: "10px 0px", display: "flex", gap: "10px", justifyContent: "center" }}>
              <CustomButton
                label={"Cancelar"}
                className="buttonColorDisabled"
                size="large"
                fullWidth
                disableRipple
                onClick={() => {
                  setInputValues("");
                  setPreview(null);
                  reset();
                  handlePasswordActual();
                }}
                disabled={stateCancelar}
              />
              <CustomButton
                label={"Confirmar"}
                disabled={!currentPassword}
                type="submit"
                variant="contained"
                className={"buttonColorDefault"}
                size="large"
                fullWidth
                disableRipple
              />
            </Box>
          </Body>
        </BoxMiCuenta>

      </form>

    </Styled >
  )
}

export default MiCuenta