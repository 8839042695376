import React from 'react';
import { useAuth } from './context/AuthContext';
import { Navigate, Outlet } from 'react-router-dom';

const ProtectedRoute = ({ requiredRole }) => {
  const { isAuthenticated, token } = useAuth();
  const role = token.rol;
  console.log(role);
  if (!isAuthenticated) return <Navigate to="/" />;
  if (requiredRole && (!role || role !== requiredRole)) return <Navigate to="/no-autorizado" />;
  return (
    <Outlet />
  )
}

export default ProtectedRoute