import React, { useEffect, useState } from 'react'
import {
  DashBoardRight,
  BoxHeaderRight,
  TitleRight,
  DescriptionRight,
  BoxTable,
} from '../../DashboardClientesStyled';
import Tables from "../../../../../../components/Tables/Tables";
import { buttonsDashboardClientes } from '../../../../../../components/ButtonsTable/ButtonsTable';
import CustomRow from '../../../../../../components/CustomRow/CustomRow.tsx';
import Modal from "../../../../../../components/Modal/Modal.jsx";
import { getLatestMuestras, getLatestOrdenesDeTrabajo } from '../../../../../../api/Inicio.js';
import { imprimirMuestras } from '../../../../../../api/Clientes.js';

const columns = [
  { id: "muestra", label: "Muestra" },
  { id: "fechaAnalisis", label: "Fecha Análisis" },
  { id: "equipo", label: "Equipo" },
  { id: "ptodetoma", label: "Pto.de Toma" },
  { id: "estadofinal", label: "Estado Final" },
  { id: "buttons" },
]

const Right = () => {
  const [statusModal, setModalStatus] = useState(false);
  const [datosModal, setDatosModal] = useState("");
  const [typeModal, setTypeModal] = useState("");

  const [rows, setRows] = useState("")

  const handleClickButonTable = (value, valueRow) => {
    if (value === "Ver") {
      setModalStatus(true);
      setTypeModal("verMuestrasClientesExternos")
      setDatosModal(valueRow);
    }

    if (value === "Imprimir") {
      handleImprimirMuestraEspecifica(valueRow.datos.id);
    }
  }

  const handleImprimirMuestraEspecifica = (id) => {
    const bodyImprimir = [id];
  
    imprimirMuestras(bodyImprimir)
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data], { type: "application/pdf" }));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "muestra-" + bodyImprimir[0] + ".pdf"); // Nombre del archivo
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        console.error("Error al descargar el PDF:", error);
      });
  };

  useEffect(() => {
    const fetchLatestMuestras = async () => {
      try {
        const res = await getLatestMuestras();
        setRows(
          res.data.map((res) => {
            const fechaFormat = res.fecha_analisis_mue;
            const fecha = new Date(fechaFormat).toLocaleString()
            return {
              muestra: res.id,
              fechaAnalisis: fecha,
              equipo: res.equipo.description,
              ptodetoma: res.punto_toma.description,
              ...(res.estado_final && {
                estadofinal: <CustomRow colorBorder={res.estado_final.color_efm} label={res.estado_final.desc_efm} svg={res.estado_final.imagen_efm}/>
              }),
              datos: res
            };
          })
        );
      } catch (error) {
        console.error(error);
      }
    };
    fetchLatestMuestras();
  }, []);

  return (
    <DashBoardRight>
      <BoxHeaderRight>
        <TitleRight>Últimos Informes de Muestras Recibidos</TitleRight>
        <DescriptionRight>Aquí podrá consultar los últimos 10 informes de muestras,
          para poder acceder a la consulta completa de informes, presione la opción “Informes de Muestras” que se encuentra en la cabecera
        </DescriptionRight>
      </BoxHeaderRight>
      <BoxTable>
        <Tables
          typeTable={"prop"}
          columns={columns}
          rows={rows}
          cRows={6}
          pagination={false}
          buttons={buttonsDashboardClientes}
          onClickButtonTable={(value, valuesRow) => { handleClickButonTable(value, valuesRow) }}
        />
        <Modal
          datos={datosModal}
          isOpen={statusModal}
          onClose={() => {
            setModalStatus(false);
          }}
          type={typeModal}
        />
      </BoxTable>
    </DashBoardRight>
  )
}

export default Right