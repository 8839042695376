import React, { useEffect,  useState } from 'react'

import Input from '../../components/Input/Input';

import CustomButton from '../../components/Button/CustomButton.tsx';
import { lookupTenant, updateTenant } from '../../api/Clientes.js';

import { useNavigate } from 'react-router-dom';
import DropZoneTenant from '../../components/DropZone/DropZoneTenant.jsx';
import Preloader from '../../components/Preloader/Preloader.jsx';
import Toast from '../../components/Toast/Toast.jsx';
import { Box, styled } from '@mui/material';
import { colors, fonts } from '../../assets/styles/theme.ts';
const CfgClientes = (props) => {
  const navigate = useNavigate();

  const [tenant, setTenant] = useState(null);

  const [tenantId, setTenantId] = useState(null);
  const [codigo, setCodigo] = useState('');
  const [denominacion, setDenominacion] = useState('');
  const [direccion, setDireccion] = useState('');
  const [telefono, setTelefono] = useState('');
  const [email, setEmail] = useState('');
  const [web, setWeb] = useState('');
  const [fileSize, setFileSize] = useState('');
  const [filePath, setFilePath] = useState('');
  const [linkManual, setLinkManual] = useState('');

  const [logo, setLogo] = useState(['', '']);

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [msjToast, setMsjToast] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const isFormValid = tenantId !== null &&
    codigo !== '' &&
    denominacion !== '' &&
    direccion !== '' &&
    telefono !== '' &&
    email !== '' &&
    web !== '' &&
    fileSize !== '' &&
    filePath !== '' &&
    linkManual !== '' &&
    logo[0] !== '' && logo[1] !== '';

  useEffect(() => {
    setIsLoading(true);

    lookupTenant(1)
      .then((res) => {
        setTenant(res.data);

        setTenantId(res.data.id);
        setCodigo(res.data.codigo);
        setDenominacion(res.data.denominacion);
        setDireccion(res.data.direccion);
        setTelefono(res.data.telefono);
        setEmail(res.data.email);
        setWeb(res.data.paginaWeb);
        setFileSize(res.data.tamanoMaximoImagenes);
        setFilePath(res.data.pathImagenes);
        setLinkManual(res.data.manualUsuario);

        setLogo([res.data.logo_content_type, res.data.logo]);

        setIsLoading(false);
      })
  }, [])

  const handleClickCancelar = () => {
    //console.log(logo);

    navigate(-1);
  }

  const handleClickConfirmar = () => {
    setIsLoading(true);

    const updateBody = {
      id: tenantId,
      codigo: codigo,
      denominacion: denominacion,
      direccion: direccion,
      email: email,
      logo: logo[1],
      logo_content_type: logo[0],
      manualUsuario: linkManual,
      paginaWeb: web,
      pathImagenes: filePath,
      tamanoMaximoImagenes: fileSize,
      telefono: telefono
    }

    updateTenant(updateBody)
      .then((res) => {
        setSuccess(true);
        setIsLoading(false);
        setMsjToast("Tenant modificado con exito.");

        //console.log(res);
      })
      .catch((err) => {
        setError(true);
        setIsLoading(false);
        setMsjToast("No se pudo editar el Tenant.");

        //console.log(err);
      });
  }

  return (
    <BoxStyled>
      <Preloader
        title="Cargando..."
        subtitle={"Espere unos Segundos por favor..."}
        loading={isLoading}
      />
      <div classname="toast">
        {success ? (
          <Toast title={msjToast} close={() => setSuccess(false)} />
        ) : null}
        {error ? (
          <Toast
            type={"error"}
            title={msjToast}
            close={() => setError(false)}
          />
        ) : null}
      </div>
      <div className="cfgClientes">
        <div className="divLabel">
          <p className="titulo"> Configuración Cliente</p>
        </div>
        <div className="divInputs">
          <div className="aDiv">
            <Input
              value={codigo}
              label="Código"
              classnamediv="divInputCodigo"
              className="input"
              style={{ width: "100%" }}
              variant="outlined"
              name="codigo"
              onChange={(e) => { setCodigo(e.target.value) }}
            />

            <Input
              value={denominacion}
              label="Denominación"
              classnamediv="divInputDenominacion"
              className="input"
              style={{ width: "100%" }}
              variant="outlined"
              name="denominacion"
              onChange={(e) => { setDenominacion(e.target.value) }} />
          </div>
          <div className="bDiv">
            <Input
              value={direccion}
              label="Dirección"
              classnamediv="divInputDireccion"
              className="input"
              style={{ width: "100%" }}
              variant="outlined"
              name="direccion"
              onChange={(e) => { setDireccion(e.target.value) }} />
            <Input
              value={telefono}
              label="Telefono"
              classnamediv="divInputTelefono"
              className="input"
              style={{ width: "100%" }}
              variant="outlined"
              name="telefono"
              onChange={(e) => { setTelefono(e.target.value) }} />
          </div>
          <div className="cDiv">
            <Input
              value={email}
              label="Email"
              classnamediv="divInputEmail"
              className="input"
              style={{ width: "100%" }}
              variant="outlined"
              name="email"
              onChange={(e) => { setEmail(e.target.value) }} />
          </div>
          <div className="dDiv">
            <Input
              value={web}
              label="Página Web"
              classnamediv="divInputPaginaWeb"
              className="input"
              style={{ width: "100%" }}
              variant="outlined"
              name="paginaWeb"
              onChange={(e) => { setWeb(e.target.value) }} />
            <Input
              value={fileSize}
              label="Tamaño Máximo de Imágenes"
              classnamediv="divInputSizeImg"
              className="input"
              style={{ width: "100%" }}
              variant="outlined"
              name="sizeImg"
              onChange={(e) => { setFileSize(e.target.value) }} />
          </div>
          <div className="cDiv">
            <DropZoneTenant
              setImagen={setLogo}
              imgDefault={logo}
              ver={false}
            />
            <div className="cDivInputs">
              <Input
                value={filePath}
                label="Path Imágenes"
                classnamediv="divInputPathImg"
                className="input"
                style={{ width: "100%" }}
                variant="outlined"
                name="pathImg"
                onChange={(e) => { setFilePath(e.target.value) }} />
              <Input
                value={linkManual}
                label="Link Manual del Usuario"
                classnamediv="divInputLinkManualUsuario"
                className="input"
                style={{ width: "100%" }}
                variant="outlined"
                name="linkManualUsuario"
                onChange={(e) => { setLinkManual(e.target.value) }} />
            </div>
          </div>
        </div>
        <div className="divButtons">
          <div className="buttons">
            <CustomButton
              onClick={handleClickCancelar}
              label={"Cancelar"}
              variant="contained"
              className={"buttonColorDisabled"}
            />
            <CustomButton
              onClick={handleClickConfirmar}
              label={"Confirmar"}
              variant="contained"
              className={"buttonColorDefault"}
              disabled={!isFormValid}
            />
          </div>
          {/*<FormControlLabel
              control={<Switch name="habilitado" checked={true} onChange={handleChange} />}
              label="Habilitar"
              sx={{ marginLeft: "auto" }}
            />*/}
        </div>
      </div>
    </BoxStyled>
  )
}
const BoxStyled = styled(Box)`
  padding:20px;
  margin: auto;
  fieldset {
      border-radius: 8px;
    }
    .divLabel {
      background-color: ${colors.lightGray};
      border-radius: 10px;
      padding: 5px;
      max-width: 100%;
      text-align: center;
      .titulo {
        font-size: 24px;
        color: ${colors.blueDark};
        font-family: ${fonts.primary};
        margin: 0px;
        font-weight: bold;
      }
    }
    .cfgClientes {
      .divInputs {
        display: flex;
        flex-direction: column;
        gap: 30px;
        width: 100%;
        padding: 10px;
        .aDiv,
        .bDiv,
        .cDiv,
        .dDiv,
        .cDiv {
          width: 100%;
          display: flex;
          gap: 20px;
          .divInputCodigo {
            width: 20%;
          }
          .divInputDenominacion {
            width: 80%;
          }
          .divInputDireccion {
            width: 70%;
          }
          .divInputTelefono {
            width: 40%;
          }
          .divInputEmail {
            width: 100%;
          }
          .divInputPaginaWeb {
            width: 70%;
          }
          .divInputSizeImg {
            width: 30%;
          }
          .cDivInputs {
            display: flex;
            flex-direction: column;
            gap: 35px;
            width: 60%;
          }
          .ehgcuo {
            width: 60%;
            .dropzone {
              width: 100%;
            }
          }
        }
      }
      .divButtons {
        display: flex;
        align-items: center;
        .buttons {
          width: 80%;
          display: flex;
          justify-content: center;
          padding: 20px;
          gap: 20px;
        }
      }
    }
    @media (max-width: 768px) {
      padding:20px;
    .cfgClientes {
        .aDiv,
        .bDiv,
        .cDiv,
        .dDiv,
        .cDiv {
          display: flex;
          flex-direction: column;
          .divInputCodigo {
            width: 100% !important;
          }
          .divInputDenominacion {
            width: 100% !important;
          }
          .divInputDireccion {
            width: 100% !important;
          }
          .divInputTelefono {
            width: 100% !important;
          }
          .divInputEmail {
            width: 100% !important;
          }
          .divInputPaginaWeb {
            width: 100% !important;
          }
          .divInputSizeImg {
            width: 100% !important;
          }
          .cDivInputs {
            display: flex;
            flex-direction: column;
            gap: 35px;
            width: 100% !important;
          }
          .ehgcuo {
            width: 97% !important;
            .dropzone {
              width: 97% !important;
            }
          }
        }
      }
    }
  `;
export default CfgClientes