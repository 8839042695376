import React, { useState, useEffect } from 'react';
import { Box, IconButton, styled } from '@mui/material';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';

const CarouselCustom = (props) => {
  const [currentImages, setCurrentImages] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (props.images?.length) {
      setCurrentImages(props.images);
      setCurrentIndex(0);
    } else {
      setCurrentImages([]);
    }
  }, [props.images]);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? currentImages.length - 1 : prevIndex - 1));
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex === currentImages.length - 1 ? 0 : prevIndex + 1));
  };

  return (
    <Styled>
      <Box
        component="img"
        src={
          currentImages.length > 0 && currentImages[currentIndex]?.imagen_mue_base64
            ? `data:${currentImages[currentIndex]?.content_type};base64,${currentImages[currentIndex]?.imagen_mue_base64}`
            : ""
        }
        alt={currentImages.length > 0 ? `Image ${currentIndex + 1}` : "No image available"}
        sx={{
          padding: "5px 0px",
          width: '100%', 
          maxHeight: '80%', 
          objectFit: 'contain', 
          display: currentImages.length > 0 ? "block" : "none" // Oculta la imagen si no hay imágenes disponibles
        }}
      />
      {currentImages.length > 1 && ( // Solo muestra los botones si hay más de una imagen
        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 0 }}>
          <IconButton onClick={handlePrev}>
            <ArrowBackIos />
          </IconButton>
          <IconButton onClick={handleNext}>
            <ArrowForwardIos />
          </IconButton>
        </Box>
      )}
    </Styled>
  );
};

export default CarouselCustom;

const Styled = styled(Box)`
  width: 100%;
  height: 91%;
  text-align: center; 
  position: relative; 
  margin: 0 auto;
  border-radius: var(--1, 8px);
  border: var(--borderRadius, 4px) solid #66bbd3;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
`;
