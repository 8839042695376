import React, { useEffect, useReducer, useState } from 'react'
import { StyledModalSuperAdmin } from '../../Styled.jsx';
import { fonts, colors } from '../../../../assets/styles/theme.ts';
import {
  FormControlLabel,
  Switch,
  Radio,
  Select,
  MenuItem,
  FormControl,
  RadioGroup,
  Checkbox,
  ListItemText,
  InputLabel,
  styled
} from '@mui/material';
import Input from '../../../Input/Input.jsx';
import CustomButton from '../../../Button/CustomButton.tsx';
import { createUser, getClienteOptions, getPlantaByClienteOptions, updateUser } from '../../../../api/Clientes.js';
import Preloader from '../../../Preloader/Preloader.jsx';

const plantasOptions = ["Planta A", "Planta B", "Planta C"];

const UsuariosSuperAdmin = (props) => {
  const [selectedValues, setSelectedValues] = useState([]);
  const [open, setOpen] = useState(false);

  const [form, setForm] = useReducer((state, action) => {
    switch (action.type) {
      case 'SET_FIELD':
        return { ...state, [action.field]: action.value };
      default:
        return state;
    }
  }, {
    nombre: props.datos.datos?.firstName ?? '',
    apellido: props.datos.datos?.lastName ?? '',
    email: props.datos.datos?.email ?? '',
    login: props.datos.datos?.login ?? '',
    password: '',
    roles: props.datos.datos?.rol ?? '',
    cliente: '',
    plantas: [],
    habilitado: props.datos.datos?.status ?? 'ACTIVE',
  });

  const [clientes, setClientes] = useState([]);
  const [selectedCliente, setSelectedCliente] = useState(null);

  const [plantas, setPlantas] = useState([]);
  const [selectedPlantas, setSelectedPlantas] = useState([]);
  const [isLoadingPlantas, setIsLoadingPlantas] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e, nameReactSelect) => {
    if (e.target) {
      const { name, value, checked, type } = e.target;
      const fieldValue = type === 'checkbox' ? checked : value;

      if (name === "roles" && value === "LABORATORIO") {
        setSelectedPlantas([])
        setPlantas([])
        setSelectedCliente(null)
      }

      if (name === "habilitado") {
        if (fieldValue) {
          setForm({ type: 'SET_FIELD', field: name, value: "ACTIVE" });
        } else {
          setForm({ type: 'SET_FIELD', field: name, value: "INACTIVE" });
        }
      } else {
        setForm({ type: 'SET_FIELD', field: name, value: fieldValue });
      }
    } else {
      const value = e.value;
      const name = nameReactSelect;
    }
  };

  const handlePlantasChange = (event) => {
    setSelectedPlantas(event.target.value);
  };

  useEffect(() => {
    const fetchClientes = async () => {
      const res = await getClienteOptions();
      const unidades = res?.data?.map((item) => ({
        value: item.id,
        label: item.desc_cli,
      }));
      setClientes(unidades);

      const clienteId = props.datos.datos?.clienteId;

      // Verificamos si clienteId tiene un valor distinto de null
      if (clienteId !== null && clienteId !== undefined) {
        // Convertimos el clienteId a un número si es un string
        const clienteIdNum = Number(clienteId);

        // Filtramos la unidad que tiene el value igual al número convertido
        const dataSelected = unidades.find(item => item.value === clienteIdNum);

        // Si encontramos la unidad correspondiente, asignamos a selectedCliente
        if (dataSelected) {
          setSelectedCliente(dataSelected);
          fetchPlantasStarting(dataSelected);
        }
      }
    };

    fetchClientes();
  }, []);

  const fetchPlantasStarting = async (e) => {
    if (e === null) {
    } else {
      setIsLoadingPlantas(true);
      const res = await getPlantaByClienteOptions(e.value);
      const unidades = res?.data?.map((item) => ({
        value: item.id,
        label: item.description,
      }));
      setPlantas(unidades);

      const plantaId = props.datos.datos?.plantaId;

      // Verificamos si plantaId tiene un valor válido
      if (plantaId && plantaId.trim() !== "") {
        // Convertimos el string de plantaId a un array de números
        const plantaIdsArray = plantaId.split(",").map((id) => Number(id.trim()));

        // Seteamos el array de IDs de plantas en setSelectedPlantas
        setSelectedPlantas(plantaIdsArray);
      }

      setIsLoadingPlantas(false);
    }
  };

  const fetchPlantas = async (e) => {
    setPlantas([]);
    setSelectedPlantas([]);
    if (e === null) {
    } else {
      setIsLoadingPlantas(true);
      const res = await getPlantaByClienteOptions(e.value);
      const unidades = res?.data?.map((item) => ({
        value: item.id,
        label: item.description,
      }));
      //console.log(unidades);
      setPlantas(unidades);
      setIsLoadingPlantas(false);
    }
  };

  const handleConfirmar = () => {
    setIsLoading(true);

    if (props.datos.datos?.id) {
      // Aca EDITAMOS
      const userInfo = {
        id: props.datos.datos?.id ?? null,
        firstName: form.nombre ?? null,
        lastName: form.apellido ?? null,
        email: form.email ?? null,
        login: form.login ?? null,
        clienteId: selectedCliente?.value.toString() ?? "",
        plantaId: selectedPlantas.length > 0 ? selectedPlantas.join(",") : "",
        rol: form.roles ?? null,
        status: form.habilitado ?? null,
      }

      if (form.password.length > 0) {
        userInfo.passwordHash = form.password;
      }

      updateUser(userInfo)
        .then((res) => {
          setIsLoading(false);
          props.onClick({
            msj: "User modificado con exito.",
            status: "success",
          });
        })
        .catch((err) => {
          setIsLoading(false);
          props.onClick({
            msj: "No se pudo modificar al User.",
            status: "error",
          });
        });

    } else {
      // Aca CREAMOS
      const userInfo = {
        firstName: form.nombre ?? null,
        lastName: form.apellido ?? null,
        email: form.email ?? null,
        login: form.login ?? null,
        clienteId: selectedCliente?.value.toString() ?? "",
        plantaId: selectedPlantas.length > 0 ? selectedPlantas.join(",") : "",
        rol: form.roles ?? null,
        status: form.habilitado ?? null,
      }

      if (form.password.length > 0) {
        userInfo.passwordHash = form.password;
      }

      createUser(userInfo)
        .then((res) => {
          setIsLoading(false);
          props.onClick({
            msj: "User creado con exito.",
            status: "success",
          });
        })
        .catch((err) => {
          setIsLoading(false);
          props.onClick({
            msj: "No se pudo crear al User.",
            status: "error",
          });
        });
    }

  }

  const isFormValid = form.nombre !== '' &&
    form.apellido !== '' &&
    form.email !== '' &&
    form.login !== '' &&
    form.roles !== '' &&
    (props.datos.datos?.id || form.password !== '');

  return (
    <StyledModalSuperAdmin>
      <Preloader
        title="Cargando..."
        subtitle={"Espere unos Segundos por favor..."}
        loading={isLoading}
      />
      <div className="usuariosSuperAdmin">
        <div className="divLabel">
          <p className="titulo">Usuario</p>
        </div>
        <form>
          <div className="divInputs">
            <FormControlLabel
              control={<Switch name="habilitado" checked={form.habilitado === 'ACTIVE' ? true : false} onChange={handleChange} />}
              label="Habilitar"
              sx={{ marginLeft: "auto" }}
            />
            <div className="aDiv">
              <Input
                value={form.nombre}
                label="Nombre"
                classnamediv="divInputNombre"
                className="input"
                style={{ width: "100%" }}
                variant="outlined"
                name="nombre"
                onChange={handleChange} />
              <Input
                value={form.apellido}
                label="Apellido"
                classnamediv="divInputApellido"
                className="input"
                style={{ width: "100%" }}
                variant="outlined"
                name="apellido"
                onChange={handleChange} />
            </div>
            <div className="bDiv">
              <Input
                value={form.email}
                label="Email"
                classnamediv="divInputEmail"
                className="input"
                style={{ width: "100%" }}
                variant="outlined"
                name="email"
                onChange={handleChange} />

            </div>
            <div className="cDiv">
              <Input
                value={form.login}
                label="Login"
                classnamediv="divInputLogin"
                className="input"
                style={{ width: "100%" }}
                variant="outlined"
                name="login"
                onChange={handleChange} />
              <Input
                value={form.password}
                label="Password"
                classnamediv="divInputPassword"
                className="input"
                style={{ width: "100%" }}
                variant="outlined"
                name="password"
                onChange={handleChange} />
            </div>
            {form.roles === "SUPERADMIN" ? <></> :
              <div className="dDiv">
                <p className="labelDdiv">Roles</p>

                <div className="divRadioButtons">
                  <FormControl sx={{ display: "flex" }}>
                    <RadioGroup value={form.roles} onChange={(e) =>
                      handleChange(
                        { target: { name: "roles", value: e.target.value } }
                      )
                    }>
                      <FormControlLabel value="TENANT" control={<Radio />} label="Laboratorio" />
                      <FormControlLabel value="CLIENTE" control={<Radio />} label="Cliente" />
                      <FormControlLabel value="PLANTA" control={<Radio />} label="Planta" />
                    </RadioGroup>
                  </FormControl>
                </div>
              </div>
            }
            {form.roles === "CLIENTE" || form.roles === "PLANTA" ?
              <div className="cDiv">
                <div className="cDivInputs">
                  <Input
                    label="Cliente"
                    classnamediv="divInputImportaciones divInput"
                    className="input inputImportaciones"
                    variant="outlined"
                    size="medium"
                    name="cliente"
                    reactSelect
                    options={clientes}
                    value={selectedCliente}
                    onChange={(e) => {
                      setSelectedCliente(e);
                      fetchPlantas(e);
                    }}
                    placeholder={selectedCliente === null ? "Cliente" : "Cliente"}
                    isDisabled={clientes.length < 1}
                    isClearable
                  />
                  <FormControlStyled fullWidth>
                    <InputLabel id="plantas-label">Plantas</InputLabel>
                    <Select
                      multiple
                      name="plantas"
                      value={selectedPlantas}
                      onChange={handlePlantasChange}
                      label="Plantas"
                      labelId="plantas-label"
                      renderValue={(selected) => selected.join(", ")}
                      disabled={plantas.length < 1}
                    >
                      {plantas.map((planta) => (
                        <MenuItem key={planta.value} value={planta.value}>
                          <Checkbox checked={selectedPlantas.indexOf(planta.value) > -1} />
                          <ListItemText primary={planta.label} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControlStyled>
                </div>
              </div> : <></>}
          </div>
          <div className="divButtons">
            <div className="buttons">
              <CustomButton
                onClick={props.onClick}
                label={"Cancelar"}
                variant="contained"
                className={"buttonColorDisabled"}
              />
              <CustomButton
                onClick={handleConfirmar}
                label={"Confirmar"}
                variant="contained"
                className={"buttonColorDefault"}
                disabled={!isFormValid}
              />
            </div>

          </div>
        </form>
      </div>
    </StyledModalSuperAdmin>
  )
}

export default UsuariosSuperAdmin

const FormControlStyled = styled(FormControl)`
  label {
    font-family: ${fonts.primary};
    color: ${colors.blueDark};
    font-size: 17px;
    font-weight: 600;
    margin: 4px 0px;
  }
`