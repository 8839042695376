import React, { useState, useEffect } from "react";
import { useParams, useSearchParams } from 'react-router-dom';
import Tables from "../../../../components/Tables/Tables";
import Modal from "../../../../components/Modal/Modal";
import Modal2 from "../../../../components/Modal/Modal";
import Input from "../../../../components/Input/Input.jsx";
import Toast from "../../../../components/Toast/Toast";
import CustomButton from "../../../../components/Button/CustomButton.tsx";
import Print from "../../../../assets/Icons/Buttons/Muestras/Print.svg";
import More from "../../../../assets/Icons/Buttons/More.svg";
import Checkboxx from "../../../../components/Checkbox/Checkboxx.jsx";
import Preloader from "../../../../components/Preloader/Preloader.jsx";
import { Box } from "@mui/material";
import { Search } from "@mui/icons-material";
import { buttonsMuestras } from "../../../../components/ButtonsTable/ButtonsTable";
import {
  StyledTypography,
  Styled,
  BoxHeader,
  BoxInputs,
  BoxSubHeader,
} from "./MuestrasStyled";
import { RenderImgStyled } from "../../../Task/Items/SuperAdminStyled";
import {
  getClienteOptions,
  getPlantabyCliente2,
  imprimirMuestras,
  imprimirResumen,
} from "../../../../api/Clientes";
import {
  getMuestrasNewFilters,
  getAllOrdenByClienteOptions,
  getAllEquipoByClienteOptions,
  deleteMuestra,
  //getAllOrdenByPlantaOptions,
  // getAllOrdenByCliente,
  // getAllPuntoTomasByCliente,
  // getAllConjuntoAnalisis,
  // getAllLubricante,
} from "../../../../api/Muestras.js";
import moment from "moment";
import { useAuth } from "../../../../context/AuthContext.jsx";
// import More from "../../../../assets/Icons/Buttons/More.svg";
// import SetIcon from "../../../../assets/Icons/Buttons/Clientes/SetIcon.svg";
// import { Search } from "@mui/icons-material";

const columns = [
  { id: "fechaProgr", label: "Fecha Progr." },
  { id: "codigo", label: "Código" },
  { id: "lote", label: "Lote" },
  { id: "denominacion", label: "Denominación" },
  { id: "clienteDesc", label: "Cliente" },
  { id: "planta", label: "Planta" },
  { id: "estado", label: "Estado" },
  { id: "buttons" },
];

const rows = [
  {
    fechaProgr: "fecha",
    codigo: "codigo",
    lote: "lote",
    denominacion: "denominacion",
    cliente: "cliente",
    planta: "planta",
    estado: "estado",
  },
];

const buttonsHeadTable = [
  {
    label: "Nueva muestra",
    classname: "buttonHeaderTable",
  },
];

const datosInput = [
  {
    placeholder: "Seleccione el Cliente",
  },
  {
    placeholder: "Seleccione la Planta",
  },
  {
    placeholder: "Seleccione la OT",
  },
  {
    placeholder: "Seleccione el Equipo",
  },
];

const datosCheckBox = [
  {
    label: "Sin Diagnóstico",
    name: "sinDiagnostico",
  },
  {
    label: "Sin Estado",
    name: "sinEstado",
  },
  {
    label: "Sin Fecha Análisis",
    name: "sinFechaAnalisis",
  },
  {
    label: "Sin Imágenes",
    name: "sinImagenes"
  },
  {
    label: "Sin Resultados",
    name: "sinResultados"
  },
  {
    label: "Sin Sugerencias",
    name: "sinSugerencias"
  },
];

const Muestras = () => {
  const { token } = useAuth(); // Accede al token del contexto
  const [searchParams, setSearchParams] = useSearchParams();

  const idCli = searchParams.get('idcli');
  const idPla = searchParams.get('idpla');
  const idOt = searchParams.get('idot');
  const idEqui = searchParams.get('ideq');

  const [isLoading, setIsLoading] = useState(idCli ? true : false);
  const [statusModal, setModalStatus] = useState(false);
  const [statusModal2, setModalStatus2] = useState(false);
  const [muestraId, setMuestraId] = useState("");
  const [datosModal, setDatosModal] = useState("");
  const [typeModal, setTypeModal] = useState("muestras");
  const [searchTerm, setSearchTerm] = useState("");
  const [muestras, setMuestras] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [itemsCount, setItemsCount] = useState(1);
  const [orderBy, setOrderBy] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [msjToast, setMsjToast] = useState("");
  const [clientes, setClientes] = useState([]);
  const [selectedOptionCli, setSelectedOptionCli] = useState(null);
  const [plantas, setPlantas] = useState([]);
  const [selectedOptionPla, setSelectedOptionPla] = useState(null);
  const [ordenTrabajo, setOrdenTrabajo] = useState([]);
  const [selectedOptionOt, setSelectedOptionOt] = useState([]);
  const [equipo, setEquipo] = useState([]);
  const [selectedOptionEquipo, setSelectedOptionEquipo] = useState(null);
  const [paginaActual, setPaginaActual] = useState(1);
  const [key, setKey] = useState(0);
  const [sinDiagnostico, setSinDiagnostico] = useState(searchParams.get('sd') === 'true' || false);
  const [sinEstado, setSinEstado] = useState(searchParams.get('se') === 'true' || false);
  const [sinFechaAnalisis, setSinFechaAnalisis] = useState(searchParams.get('sf') === 'true' || false);
  const [sinImagenes, setSinImagenes] = useState(searchParams.get('si') === 'true' || false);
  const [sinResultados, setSinResultados] = useState(searchParams.get('sr') === 'true' || false);
  const [sinSugerencias, setSinSugerencias] = useState(searchParams.get('ss') === 'true' || false);

  useEffect(() => {
    if (!clientes.length) {
      getClienteOptions().then((res) => {
        setClientes([]);
        res.data.forEach((res) => {
          setClientes((prevClientes) => {
            return [...prevClientes, { label: res.desc_cli, value: res.id }];
          });

          if (token.idCliente !== "" && res.id === parseInt(token.clienteId, 10)) {
            setSelectedOptionCli({
              label: res.desc_cli,
              value: res.id
            })

            getPlantabyCliente2(res.id).then((res) => {
              setPlantas([]);  // Limpiar plantas antes de actualizar

              res.data.forEach((res) => {
                // Chequeo para token.plantaId
                if (token.plantaId === "") {
                  // Caso 1: Si token.plantaId es vacío, continua normal
                  setPlantas((prevPlantas) => [...prevPlantas, { label: res.desc_pla, value: res.id }]);
                } else if (token.plantaId.split(",").length === 1) {
                  // Caso 2: Si token.plantaId es un único número
                  if (token.plantaId === res.id.toString()) {
                    setSelectedOptionPla({ label: res.desc_pla, value: res.id });
                    setPlantas((prevPlantas) => [...prevPlantas, { label: res.desc_pla, value: res.id }]);
                  }
                } else {
                  // Caso 3: Si token.plantaId es una secuencia de números
                  const plantaIds = token.plantaId.split(",").map(id => id.trim());
                  if (plantaIds.includes(res.id.toString())) {
                    setPlantas((prevPlantas) => [...prevPlantas, { label: res.desc_pla, value: res.id }]);
                  } else {
                    setPlantas((prevPlantas) => [...prevPlantas]); // Mantener el set anterior si no coincide
                  }
                }

                if (idPla && idPla.toString() === res.id.toString()) {
                  setSelectedOptionPla({
                    label: res.desc_pla,
                    value: res.id,
                  });
                }
              });
            });

            getAllOrdenByClienteOptions(res.id).then((res) => {
              setOrdenTrabajo([]);
              res.data.forEach((res) => {
                if (idOt && idOt.toString() === res.id.toString()) {
                  setSelectedOptionOt({
                    label: res.desc_otra,
                    value: res.id,
                  });
                }
                setOrdenTrabajo((prevOrdenTrabajo) => {
                  return [...prevOrdenTrabajo, { label: res.desc_otra, value: res.id }];
                });
              })
            });

            getAllEquipoByClienteOptions(res.id).then((res) => {
              setEquipo([]);
              res.data.forEach((res) => {
                if (idEqui && idEqui.toString() === res.id.toString()) {
                  setSelectedOptionEquipo({
                    label: res.desc_equi,
                    value: res.id,
                  });
                }
                setEquipo((prevEquipo) => {
                  return [...prevEquipo, { label: res.desc_equi, value: res.id }];
                });
              });
              setIsLoading(false);
            });
          }

          if (idCli && idCli.toString() === res.id.toString()) {
            setSelectedOptionCli({
              label: res.desc_cli,
              value: res.id,
            });

            getPlantabyCliente2(idCli).then((res) => {
              setPlantas([]);
              res.data.forEach((res) => {
                if (idPla && idPla.toString() === res.id.toString()) {
                  setSelectedOptionPla({
                    label: res.desc_pla,
                    value: res.id,
                  });
                }
                setPlantas((prevPlantas) => {
                  return [...prevPlantas, { label: res.desc_pla, value: res.id }];
                });
              })
            });

            getAllOrdenByClienteOptions(idCli).then((res) => {
              setOrdenTrabajo([]);
              res.data.forEach((res) => {
                if (idOt && idOt.toString() === res.id.toString()) {
                  setSelectedOptionOt({
                    label: res.desc_otra,
                    value: res.id,
                  });
                }
                setOrdenTrabajo((prevOrdenTrabajo) => {
                  return [...prevOrdenTrabajo, { label: res.desc_otra, value: res.id }];
                });
              })
            });

            getAllEquipoByClienteOptions(idCli).then((res) => {
              setEquipo([]);
              res.data.forEach((res) => {
                if (idEqui && idEqui.toString() === res.id.toString()) {
                  setSelectedOptionEquipo({
                    label: res.desc_equi,
                    value: res.id,
                  });
                }
                setEquipo((prevEquipo) => {
                  return [...prevEquipo, { label: res.desc_equi, value: res.id }];
                });
              });
              setIsLoading(false);
            });
          }
        })
      });

    }

    fetchMuestrasAllFilters(
      paginaActual,
      orderBy,
      searchTerm,
      "primera",
      idCli || selectedOptionCli?.value,
      idPla || selectedOptionPla?.value,
      idOt || selectedOptionOt?.value,
      idEqui || selectedOptionEquipo?.value,
      sinDiagnostico,
      sinEstado,
      sinFechaAnalisis,
      sinImagenes,
      sinResultados,
      sinSugerencias
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginaActual, orderBy]); // Ignorando fetchMuestrasAllFilters y searchTerm en la dependencia array

  const fetchMuestrasAllFilters = async (
    pagina,
    order,
    search,
    action,
    idCli = "",
    idPla = "",
    idOt = "",
    idEqui = "",
    sindiagnostico = false,
    sinestado = false,
    sinfechaAnalisis = false,
    sinimagenes = false,
    sinresultados = false,
    sinsugerencias = false
  ) => {
    const res = await getMuestrasNewFilters(
      idCli,
      idPla,
      idOt,
      idEqui,
      pagina,
      order,
      search,
      sindiagnostico,
      sinestado,
      sinfechaAnalisis,
      sinimagenes,
      sinresultados,
      sinsugerencias
    );
    // console.log("getMuestrasAllFilters: ", res);
    setPageCount(res.data.totalPages);
    setItemsCount(res.data.totalItems);

    if (action === "new") {
      if (res.data.items.length + 1 === 21) {
        setPaginaActual(res.data.totalPages);
      }
    } else if (action === "delete") {
      setKey(key + 1);
      setMsjToast("Muestra eliminada con exito.");
      setSuccess(true);
    } else {
      if (res.data.totalPages === 0) {
        setPaginaActual(1);
      } else if (res.data.totalPages < paginaActual) {
        setPaginaActual(res.data.totalPages);
      } else {
        setPaginaActual(paginaActual);
      }
    }

    setMuestras(
      res.data.items.map((res) => {
        let fechaFormateada = moment(res.fecha_toma_mue).format("DD/MM/YYYY");
        // console.log(res)
        return {
          fechaProgr: fechaFormateada, //res.fecha_toma_mue,
          codigo: res.codigo_mue,
          lote: res.orden_trabajo.nro_lote_otra,
          denominacion: res.desc_mue,
          cliente: res.orden_trabajo.cliente.id,
          clienteDesc: res.orden_trabajo.cliente.desc_cli,
          planta: res.planta.description,
          estado: res.estado_mue,
          id: res.id,
          horasUso: res.horas_equipo_mue,
          horasUsoLubricante: res.horas_lubricante_mue,
          responsable: res.responsable_toma_mue,
          punto: res.punto_toma.description,
          ordenTrabajo: res.orden_trabajo,
          puntoToma: res.punto_toma,
          conjunto: res.conjunto_analisis,
          lubricante: res.lubricante,
          fechaTomaMue: res.fecha_toma_mue,
        };
      })
    );
    // setIsLoading(false);
  };

  const handleImprimirResumen = () => {
    const bodyImprimir = muestras.map(muestra => muestra.id);

    imprimirResumen(bodyImprimir)
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data], { type: "application/pdf" }));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "resumen.pdf"); // Nombre del archivo
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        console.error("Error al descargar el PDF:", error);
      });
  };

  const handleImprimirMuestras = () => {
    const bodyImprimir = muestras.map(muestra => muestra.id);

    imprimirMuestras(bodyImprimir)
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data], { type: "application/pdf" }));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "muestras.pdf"); // Nombre del archivo
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        console.error("Error al descargar el PDF:", error);
      });
  };

  const handleImprimirMuestraEspecifica = (id) => {
    const bodyImprimir = id;

    imprimirMuestras(bodyImprimir)
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data], { type: "application/pdf" }));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "muestra-" + bodyImprimir[0] + ".pdf"); // Nombre del archivo
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        console.error("Error al descargar el PDF:", error);
      });
  };

  const handleClickButtonTable = (value, valueRow) => {
    const val = value
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase();

    if (value === "crear") {
      setModalStatus(true);
      setTypeModal("muestras");
      setDatosModal("");
    }
    if (value === "editar") {
      // console.log("entre2");
      setModalStatus(true);
      setTypeModal("editar");
      setDatosModal(valueRow);
    }
    if (val !== "eliminar" && val !== "diagnostico") {
      setModalStatus(true);
      setTypeModal("muestras");
      setDatosModal({ button: value.toLowerCase(), datos: valueRow });
    }
    if (val === "diagnostico") {
      setModalStatus(true);
      setTypeModal("diagMuestras");
      setDatosModal({ button: value.toLowerCase(), datos: valueRow });
    }
    if (val === "eliminar") {
      setMuestraId(valueRow.id);
      setModalStatus2(true);
    }
  };

  const handleClickButtonHeader = (e) => {
    setDatosModal("");
    setModalStatus(true);
  };

  const handleOrder = (value) => {
    let valor;
    if (orderBy === "" || orderBy.replace("-", "").length !== value.length) {
      valor = value;
    } else {
      valor = orderBy;
    }

    if (valor.indexOf("-") > -1) {
      const updatedValue = valor.replace("-", "");
      setOrderBy(updatedValue);
    } else {
      const updatedValue = "-" + valor;
      setOrderBy(updatedValue);
    }
  };

  const handleChangeCliente = (value) => {

    setSelectedOptionCli(!value ? null : value);

    setSelectedOptionPla(null);
    setPlantas([]);
    setSelectedOptionOt(null);
    setOrdenTrabajo([]);
    setSelectedOptionEquipo(null);
    setEquipo([]);

    if (value !== null) {
      getPlantabyCliente2(value.value).then((res) => {
        // console.log("getPlantabyCliente2: ", res.data);
        setPlantas(
          res.data.map((res) => {
            return { label: res.desc_pla, value: res.id };
          })
        );
      });

      getAllOrdenByClienteOptions(value.value).then((res) => {
        // console.log("OrdenByCliente: ", res.data);
        setOrdenTrabajo(
          res.data.map((res) => {
            return { label: res.desc_otra, value: res.id };
          })
        );
      });

      getAllEquipoByClienteOptions(value.value).then((res) => {
        // console.log("EquipoByCliente: ", res.data);
        setEquipo(
          res.data.map((res) => {
            return { label: res.desc_equi, value: res.id };
          })
        );
      });
    }

    fetchMuestrasAllFilters(
      paginaActual,
      orderBy,
      searchTerm,
      "filter",
      value ? value.value : "",
      selectedOptionPla === null ? "" : selectedOptionPla.value,
      selectedOptionOt === null ? "" : selectedOptionOt.value,
      selectedOptionEquipo === null ? "" : selectedOptionEquipo.value,
      sinDiagnostico,
      sinEstado,
      sinFechaAnalisis,
      sinImagenes,
      sinResultados,
      sinSugerencias
    );
  };

  const handleChangePlanta = (value) => {
    setSelectedOptionPla(!value ? null : value);
    fetchMuestrasAllFilters(
      paginaActual,
      orderBy,
      searchTerm,
      "filter",
      selectedOptionCli === null ? "" : selectedOptionCli.value,
      value ? value.value : "",
      selectedOptionOt === null ? "" : selectedOptionOt.value,
      selectedOptionEquipo === null ? "" : selectedOptionEquipo.value,
      sinDiagnostico,
      sinEstado,
      sinFechaAnalisis,
      sinImagenes,
      sinResultados,
      sinSugerencias
    );
  };

  const handleChangeOt = (value) => {
    // console.log("handleChangeOt: ", value);
    setSelectedOptionOt(!value ? null : value);
    fetchMuestrasAllFilters(
      paginaActual,
      orderBy,
      searchTerm,
      "filter",
      selectedOptionCli === null ? "" : selectedOptionCli.value,
      selectedOptionPla === null ? "" : selectedOptionPla.value,
      value ? value.value : "",
      selectedOptionEquipo === null ? "" : selectedOptionEquipo.value,
      sinDiagnostico,
      sinEstado,
      sinFechaAnalisis,
      sinImagenes,
      sinResultados,
      sinSugerencias
    );
  };

  const handleChangeEquipo = (value) => {
    // console.log("handleChangeEquipo: ", value);
    setSelectedOptionEquipo(!value ? null : value);
    fetchMuestrasAllFilters(
      paginaActual,
      orderBy,
      searchTerm,
      "filter",
      selectedOptionCli === null ? "" : selectedOptionCli.value,
      selectedOptionPla === null ? "" : selectedOptionPla.value,
      selectedOptionOt === null ? "" : selectedOptionOt.value,
      value ? value.value : "",
      sinDiagnostico,
      sinEstado,
      sinFechaAnalisis,
      sinImagenes,
      sinResultados,
      sinSugerencias

    );
  };

  const handleBuscador = (value) => {
    setSearchTerm(value);
    fetchMuestrasAllFilters(
      paginaActual,
      orderBy,
      value,
      "search",
      selectedOptionCli === null ? "" : selectedOptionCli.value,
      selectedOptionPla === null ? "" : selectedOptionPla.value,
      selectedOptionOt === null ? "" : selectedOptionOt.value,
      selectedOptionEquipo === null ? "" : selectedOptionEquipo.value,
      sinDiagnostico,
      sinEstado,
      sinFechaAnalisis,
      sinImagenes,
      sinResultados,
      sinSugerencias
    );
  };

  const handleDeleteMuestra = (id) => {
    setModalStatus2(false);
    deleteMuestra(id).then(() => {
      setMuestraId("");
      let paginaActualTemp = paginaActual;
      if (muestras.length === 1 && paginaActualTemp > 1) {
        paginaActualTemp = paginaActualTemp - 1;
        setPaginaActual(paginaActualTemp);
      }

      return fetchMuestrasAllFilters(
        paginaActual,
        orderBy,
        searchTerm,
        "delete",
        selectedOptionCli === null ? "" : selectedOptionCli.value,
        selectedOptionPla === null ? "" : selectedOptionPla.value,
        selectedOptionOt === null ? "" : selectedOptionOt.value,
        selectedOptionEquipo === null ? "" : selectedOptionEquipo.value,
        sinDiagnostico,
        sinEstado,
        sinFechaAnalisis,
        sinImagenes,
        sinResultados,
        sinSugerencias
      );
    });
  };

  const handleChangeCheckBox = (nombre, value) => {
    const setters = {
      'sinDiagnostico': setSinDiagnostico,
      'sinEstado': setSinEstado,
      'sinFechaAnalisis': setSinFechaAnalisis,
      'sinImagenes': setSinImagenes,
      'sinResultados': setSinResultados,
      'sinSugerencias': setSinSugerencias,
    };

    setters[nombre](value);

    fetchMuestrasAllFilters(
      paginaActual,
      orderBy,
      searchTerm,
      "changeCheckBox",
      selectedOptionCli === null ? "" : selectedOptionCli.value,
      selectedOptionPla === null ? "" : selectedOptionPla.value,
      selectedOptionOt === null ? "" : selectedOptionOt.value,
      selectedOptionEquipo === null ? "" : selectedOptionEquipo.value,
      nombre === 'sinDiagnostico' ? value : sinDiagnostico,
      nombre === "sinEstado" ? value : sinEstado,
      nombre === "sinFechaAnalisis" ? value : sinFechaAnalisis,
      nombre === "sinImagenes" ? value : sinImagenes,
      nombre === "sinResultados" ? value : sinResultados,
      nombre === "sinSugerencias" ? value : sinSugerencias
    );
  };

  const cargarCheckbox = (nameCheck) => {
    if (nameCheck === "sinDiagnostico" && sinDiagnostico === true) return true;
    else if (nameCheck === "sinEstado" && sinEstado === true) return true;
    else if (nameCheck === "sinFechaAnalisis" && sinFechaAnalisis === true) return true;
    else if (nameCheck === "sinImagenes" && sinImagenes === true) return true;
    else if (nameCheck === "sinResultados" && sinResultados === true) return true;
    else if (nameCheck === "sinSugerencias" && sinSugerencias === true) return true;
    else return false;
  }

  return (
    <Styled>

      <Preloader
        title="Cargando..."
        subtitle={"Espere unos Segundos por favor..."}
        loading={isLoading}
      />

      <div classname="toast">
        {success ? (
          <Toast key={key} title={msjToast} close={() => setSuccess(false)} />
        ) : null}
        {error ? (
          <Toast
            key={key}
            type={"error"}
            title={msjToast}
            close={() => setError(false)}
          />
        ) : null}
      </div>

      <Modal
        datos={datosModal}
        isOpen={statusModal}
        type={typeModal}
        onClose={({ msj, status }) => {

          setSuccess(false);
          setError(false);
          if (status === "success") {
            setKey(key + 1);
            if (msj !== "Diagnostico") setSuccess(true);
            if (msj !== "Diagnostico") setMsjToast(msj);
            fetchMuestrasAllFilters(
              paginaActual,
              orderBy,
              searchTerm,
              msj.includes("cread") ? "new" : "",
              selectedOptionCli === null ? "" : selectedOptionCli.value,
              selectedOptionPla === null ? "" : selectedOptionPla.value,
              selectedOptionOt === null ? "" : selectedOptionOt.value,
              selectedOptionEquipo === null ? "" : selectedOptionEquipo.value,
              sinDiagnostico,
              sinEstado,
              sinFechaAnalisis,
              sinImagenes,
              sinResultados,
              sinSugerencias
            );
          } else if (status === "error") {
            // setKey(key + 1);
            setError(true);
            setMsjToast(msj);
          } else if (status === "cancel") {
            fetchMuestrasAllFilters(
              paginaActual,
              orderBy,
              searchTerm,
              msj.includes("cread") ? "new" : "",
              selectedOptionCli === null ? "" : selectedOptionCli.value,
              selectedOptionPla === null ? "" : selectedOptionPla.value,
              selectedOptionOt === null ? "" : selectedOptionOt.value,
              selectedOptionEquipo === null ? "" : selectedOptionEquipo.value,
              sinDiagnostico,
              sinEstado,
              sinFechaAnalisis,
              sinImagenes,
              sinResultados,
              sinSugerencias
            );
          }
          setModalStatus(false);
        }}
      />
      <StyledTypography>Muestras</StyledTypography>

      <BoxHeader>
        <BoxInputs>
          {/* <HeaderTable
          styledHead="muestras"
          muestras
          title="Muestras"
          datosButtons={buttonsHeadTable}
          valueBuscador={(value) => handleBuscador(value)}
          onClickButton={(e) => {
            handleClickButtonHeader(e);
          }}
          multiInputs
          selectInput={(value) => console.log(value)}
          buttonSet={() => {
            setSearchTerm("");
          }}
          // clean
        /> */}
          <Input
            inputProp={true}
            positionIcon={"start"}
            placeholder={"Buscar por número"}
            icon={<Search />}
            size={"small"}
            search={"true"}
            // value={searchTerm}
            // isClearable={() =>setSearchTerm("")}
            onChange={(e) => handleBuscador(e.target.value)}
          />
          {token.clienteId === "" ? <Input
            isClearable
            reactSelect
            options={clientes}
            stylesHeader
            onChange={(e) => {
              handleChangeCliente(e);
            }}
            placeholder={"Seleccione el cliente"}
            value={selectedOptionCli}
          /> : <></>}
          {(token.plantaId === "" || token.plantaId.split(",").length > 1) ? (<Input
            isClearable
            reactSelect
            options={plantas}
            stylesHeader
            onChange={(e) => {
              handleChangePlanta(e);
            }}
            value={selectedOptionPla}
            placeholder={
              selectedOptionCli === null
                ? "Seleccione cliente primero"
                : "Planta."
            }
            isDisabled={selectedOptionCli === null}
          />) : <></>}
          <Input
            isClearable
            reactSelect
            options={ordenTrabajo}
            stylesHeader
            onChange={(e) => {
              handleChangeOt(e);
            }}
            value={selectedOptionOt}
            placeholder={
              selectedOptionCli === null ? "Seleccione cliente primero" : "Ot"
            }
            isDisabled={selectedOptionCli === null}
          />
          <Input
            isClearable
            reactSelect
            options={equipo}
            stylesHeader
            onChange={(e) => {
              handleChangeEquipo(e);
            }}
            value={selectedOptionEquipo}
            placeholder={
              selectedOptionCli === null
                ? "Seleccione cliente primero"
                : "Equipo"
            }
            isDisabled={selectedOptionCli === null}
          />
        </BoxInputs>
        <CustomButton
          label={"Nueva muestra"}
          startIcon={<RenderImgStyled img={More} />}
          variant={"contained"}
          className="buttonHeaderTable"
          onClick={(e) => {
            handleClickButtonHeader(e);
          }}
        />
      </BoxHeader>

      <BoxSubHeader>
        <Box>
          {datosCheckBox?.map((res, index) => {
            return (
              <Checkboxx
                key={index}
                label={res.label}
                checked={cargarCheckbox(res.name)}
                onChange={(e) => {
                  handleChangeCheckBox(res.name, e.checked);
                }}
              />
            );
          })}
        </Box>
        <Box>
          <CustomButton
            label={"Muestras"}
            startIcon={<RenderImgStyled img={Print} />}
            variant={"contained"}
            className="buttonColorTable"
            onClick={() => {
              handleImprimirMuestras();
            }}
          />
          <CustomButton
            label={"Resumen"}
            startIcon={<RenderImgStyled img={Print} />}
            variant={"contained"}
            className="buttonColorTable"
            onClick={() => {
              handleImprimirResumen();
            }}
          />
        </Box>
      </BoxSubHeader>

      <Tables
        typeTable={"prop"}
        columns={columns}
        rows={muestras}
        cRows={20}
        totalRows={itemsCount}
        actualpage={paginaActual}
        pagination={true}
        onClickSwitch={(value) => {
          handleOrder(value);
        }}
        page={(e) => setPaginaActual(e + 1)}
        pages={pageCount}
        buttons={buttonsMuestras}
        onClickButtonTable={(value, valuesRow) => {
          if (value === "Imprimir") {
            handleImprimirMuestraEspecifica([valuesRow.id])
          } else {
            handleClickButtonTable(value, valuesRow);
          }
        }}
      />

      <Modal2
        type="eliminar"
        isOpen={statusModal2}
        onClose={() => setModalStatus2(false)}
        onClickSuccess={() => {
          handleDeleteMuestra(muestraId);
        }}
      />
    </Styled>
  );
};

export default Muestras;

