import React from "react";
// import { StyledModal, StyledModalNav } from "../Modal/Styled";

import { Modal as CustomModal, ClickAwayListener } from '@mui/material';
import Default from './items/Default';
import Nav from "../Modal/items/Nav";
import styled from "styled-components";

import Eliminar from "../../components/Modal/items/Eliminar";
//CFG
import Unidades from "../Modal/items/Cfg/Unidad/Unidad";
import TipoUnidades from "./items/Cfg/Unidad/TipoUnidades";
import Analisis from "./items/Cfg/Analisis/Analisis";
import TipoDeAnalisis from "./items/Cfg/Analisis/TipoDeAnalisis";
import CEAnalisis from "./items/Cfg/ConjuntoAnalisis/CEAnalisis";
import ItemConjuntoAnalisis from "./items/Cfg/ConjuntoAnalisis/ItemConjuntoAnalisis";
import TipoCEAnalisis from "./items/Cfg/ConjuntoAnalisis/TipoCEAnalisis";
import Lubricantes from "./items/Cfg/Lubricantes/Lubricantes";
import TipoLubricantes from "./items/Cfg/Lubricantes/TipoLubricantes";
import TipoDeEventos from "./items/Cfg/TiposDeEventos/TiposEventos";
import Componentes from "./items/Cfg/Componentes/Componentes";
import Metodos from "./items/Cfg/Metodos/Metodos";
import EstadosFinalesModal from "./items/Cfg/EstadosFinalesModal/EstadosFinalesModal";
import ItemEstadosFinalesModal from "./items/Cfg/EstadosFinalesModal/ItemEstadosFinalesModal";
import TiposDeFallasModal from "./items/Cfg/TiposDeFallas/TiposDeFallasModal";
import CausasDeFallasModal from "./items/Cfg/CausasDeFalla/CausasDeFallasModal";
import TemplateEmailsModal from "./items/Cfg/TemplateEmails/TemplateEmailsModal";
import EstadosOrdenesDeTrabajoModal from "./items/Cfg/EstadosOrdenesDeTrabajo/EstadosOrdenesDeTrabajoModal";
//CLIENTES
import ClientesModal from "./items/Clientes/Clientes/ClientesModal";
import PlantasModal from "./items/Clientes/Plantas/PlantasModal";
import PuntosDeToma from "./items/Clientes/PuntosDeToma/PuntosDeTomaModal";
import EquiposModal from "./items/Clientes/Equipos/EquiposModal";
import InterVencionesModal from "./items/Clientes/Intervenciones/IntervencionesModal";
//MUESTRAS
import MuestrasModal from "./items/Muestras/MuestrasModal/MuestrasModal";
import DiagMuestrasModal from "./items/Muestras/MuestrasModal/DiagMuestras/DiagMuestrasModal";
import NuevoResultadoModal from "./items/Muestras/MuestrasModal/DiagMuestras/NuevoResultadoModal";
import OrdenesDeTrabajoModal from "./items/Muestras/OrdenesDeTrabajo/OrdenesDeTrabajoModal";
import NotiOrdenesDeTrabajo from "./items/Muestras/OrdenesDeTrabajo/NotiOrdenesDeTrabajo";
import ImportacionDeResultadosModal from "./items/Muestras/ImportacionDeResultados/ImportacionDeResultadosModal";
import ImportacionImagenesModal from "./items/Muestras/ImportacionImagenes/ImportacionImagenesModal";
import CargaManualResultadosModal from "./items/Muestras/CargaManualResultados/CargaManualResultadosModal";
import DiagMasivoModal from "./items/Muestras/DiagMasivo/DiagMasivoModal";
import Confirmacion from "./items/Muestras/DiagMasivo/Confirmacion.jsx";
import ModalChange from "./items/Muestras/MuestrasModal/ModalChange.jsx";
//PROVEEDORES
import ProveedoresModal from "./items/Proveedores/ProveedoresModal";
//REPORTES
import ReportesModal from "./items/Reportes/ReportesModal";
import ModalGraph from "./items/Reportes/ReportesGraphModal";
//CLIENTES EXTERNOS
import InfoMuestrasModal from "./items/ClientesExternos/InfoMuestrasModal";
import IntervencionesClientesExternosModal from "./items/ClientesExternos/IntervencionesClientesExternosModal";
import ViewMuestras from "./items/ClientesExternos/ViewMuestras/ViewMuestras.jsx";
import ModalDuplicate from "./items/ClientesExternos/ViewMuestras/Items/ModalDuplicate.jsx";
//SUPER ADMIN
import UsuariosSuperAdmin from "./items/SuperAdmin/UsuariosSuperAdminModal.jsx";
import DeleteUsuariosSuperAdminModal from "./items/SuperAdmin/DeleteUsuariosSuperAdminModal.jsx";
const Modal = (props) => {
  if (!props.isOpen) {
    return null;
  }


  const Styled = props.modalNav ? StyledModalNav : StyledModal;

  return (

    <CustomModal open={props.isOpen}>

      <Styled>

        {props.type === 'nav' && <Nav
          onClick={props.onClose}
          datos={props.datos ?? []}
          role={props.role ?? ""}
          labelModal={props.labelModal ?? ""}
          onModal={props.onModal}
          anchorEl={props.anchorEl}
        />}
        {props.type === 'default' && <Default html={props.html} iconX={props.iconX} onClose={props.onClose} />}

        {props.type === "eliminar" && <Eliminar onClick={props.onClose} onClickSuccess={props.onClickSuccess} />}

        {props.type === 'unidades' && <Unidades onClick={props.onClose} datos={props.datos ?? []} />}
        {props.type === 'tipoUnidades' && <TipoUnidades onClick={props.onClose} datos={props.datos ?? []} />}
        {props.type === 'analisis' && <Analisis onClick={props.onClose} datos={props.datos ?? []} />}
        {props.type === 'tipoAnalisis' && <TipoDeAnalisis onClick={props.onClose} datos={props.datos ?? []} />}
        {props.type === 'conjuntoAnalisis' && <CEAnalisis onClick={props.onClose} datos={props.datos ?? []} />}
        {props.type === 'itemConjuntoAnalisis' && <ItemConjuntoAnalisis onClick={props.onClose} datos={props.datos ?? []} />}
        {props.type === 'tipoConjuntoAnalisis' && <TipoCEAnalisis onClick={props.onClose} datos={props.datos ?? []} />}
        {props.type === 'lubricantes' && <Lubricantes onClick={props.onClose} datos={props.datos ?? []} />}
        {props.type === 'tipoLubricantes' && <TipoLubricantes onClick={props.onClose} datos={props.datos ?? []} />}
        {props.type === 'tipoEventos' && <TipoDeEventos onClick={props.onClose} datos={props.datos ?? []} />}
        {props.type === 'componentes' && <Componentes onClick={props.onClose} datos={props.datos ?? []} />}
        {props.type === 'metodos' && <Metodos onClick={props.onClose} datos={props.datos ?? []} />}
        {props.type === 'estadosFinales' && <EstadosFinalesModal onClick={props.onClose} datos={props.datos ?? []} />}
        {props.type === 'itemEstadosFinales' && <ItemEstadosFinalesModal onClick={props.onClose} datos={props.datos ?? []} />}
        {props.type === 'tiposDeFallas' && <TiposDeFallasModal onClick={props.onClose} datos={props.datos ?? []} />}
        {props.type === 'causasDeFallas' && <CausasDeFallasModal onClick={props.onClose} datos={props.datos ?? []} />}
        {props.type === 'templateEmails' && <TemplateEmailsModal onClick={props.onClose} datos={props.datos ?? []} />}
        {props.type === 'estadoOrdenesDeTrabajo' && <EstadosOrdenesDeTrabajoModal onClick={props.onClose} datos={props.datos ?? []} />}

        {props.type === 'clientes' && <ClientesModal onClick={props.onClose} datos={props.datos ?? []} />}
        {props.type === 'plantas' && <PlantasModal onClick={props.onClose} datos={props.datos ?? []} />}
        {props.type === 'puntosDeToma' && <PuntosDeToma onClick={props.onClose} datos={props.datos ?? []} />}
        {props.type === 'equipos' && <EquiposModal onClick={props.onClose} datos={props.datos ?? []} />}
        {props.type === 'intervenciones' && <InterVencionesModal onClick={props.onClose} datos={props.datos ?? []} />}
        {props.type === 'intervencionesClientes' && <IntervencionesClientesExternosModal onClick={props.onClose} datos={props.datos ?? []} />}

        {props.type === 'muestras' && <MuestrasModal onClick={props.onClose} datos={props.datos ?? []} />}
        {props.type === 'diagMuestras' && <DiagMuestrasModal onClick={props.onClose} datos={props.datos ?? []} />}
        {props.type === 'nuevoResultadoDiagMuestras' && <NuevoResultadoModal onClick={props.onClose} datos={props.datos ?? []} />}
        {props.type === 'cambioDeEstado' && <ModalChange onClick={props.onClose} onCloseDos={props.onCloseDos} confirmar={props.confirmar} />}

        {props.type === 'ordenesTrabajo' && <OrdenesDeTrabajoModal onClick={props.onClose} datos={props.datos ?? []} />}
        {props.type === 'notiordenesdetrabajo' && <NotiOrdenesDeTrabajo onClick2={props.onClickSuccess} onClick={props.onClose} datos={props ?? []} />}
        {props.type === 'importacionResultados' && <ImportacionDeResultadosModal onClick={props.onClose} datos={props ?? []} />}
        {props.type === 'importacionImagenes' && <ImportacionImagenesModal onClick={props.onClose} datos={props ?? []} />}
        {props.type === 'cargaManualResultados' && <CargaManualResultadosModal onClick={props.onClose} datos={props ?? []} />}
        {props.type === 'diagMasivo' && <DiagMasivoModal onClick={props.onClose} datos={props ?? []} />}
        {props.type === 'diagMasivoConfirmacion' && <Confirmacion onClick={props.onClose} reasignar={props.reasignar} />}

        {props.type === 'proveedores' && <ProveedoresModal onClick={props.onClose} datos={props.datos ?? []} />}

        {props.type === 'reportes' && <ReportesModal onClick={props.onClose} datos={props.datos ?? []} />}
        {props.type === 'reportesGraph' && <ModalGraph onClick={props.onClose} datos={props.datos ?? []} />}
        {/* //Clientes externos*/}
        {props.type === 'infoMuestras' && <InfoMuestrasModal onClick={props.onClose} datos={props.datos ?? []} />}
        {props.type === 'intervencionesClientesExternos' && <IntervencionesClientesExternosModal onClick={props.onClose} datos={props.datos ?? []} />}
        {props.type === 'verMuestrasClientesExternos' && <ViewMuestras onClick={props.onClose} datos={props.datos ?? []} />}
        {props.type === 'modalDuplicate' && <ModalDuplicate onClick={props.onClose} datos={props.datos ?? []} />}
        {/* //SUPER ADMIN*/}
        {props.type === "usuariosSuperAdmin" && <UsuariosSuperAdmin onClick={props.onClose} datos={props.datos ?? []} />}
        {props.type === "deleteUsuariosSuperAdmin" && <DeleteUsuariosSuperAdminModal onClick={props.onClose} datos={props.datos ?? []} />}
      </Styled>

    </CustomModal >

  )
}

export default Modal;

const StyledModalNav = styled.div`
  /* display: flex;
  position: absolute;
  top: 10%;
  left: 20%;
  width: 100%;
  height: 100%;

  z-index: 100; 
  justify-content: center;
  align-items: center; */
`

const StyledModal = styled.div`
/* position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
background-color: rgba(0, 0, 0, 0.5);
z-index: 1000; */
`


