import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import HeaderTable from '../../components/HeaderTable/HeaderTable';
import Tables from '../../components/Tables/Tables';
import Modal from '../../components/Modal/Modal';
import { buttonsReportes } from '../../components/ButtonsTable/ButtonsTable';
import { useAuth } from '../../context/AuthContext';
import { getAllReportes } from '../../api/Clientes';

const columns = [
  { id: "fecha", label: "Fecha Reporte" },
  { id: "tipo", label: "Tipo de Reporte" },
  { id: "autor", label: "Autor" },
  { id: "cliente", label: "Cliente" },
  { id: "buttons" },
]
const rows = [
  {
    fReporte: "2024-12-01",
    tReporte: "Financiero",
    autor: "Juan Pérez",
    cliente: "ACME Corp."
  }
];

const Reportes = () => {
  const { token } = useAuth();
  const [statusModal, setModalStatus] = useState(false);
  const [datosModal, setDatosModal] = useState("");

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [msjToast, setMsjToast] = useState("");
  const [key, setKey] = useState(0);

  const [rows, setRows] = useState([]);
  const [orderBy, setOrderBy] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [paginaActual, setPaginaActual] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [itemsCount, setItemsCount] = useState(0);

  const fetchReportes = () => {
    getAllReportes(paginaActual, orderBy, searchTerm).then((res) => {
      const modifiedItems = res.data.items.map(item => {
        let data = {};

        data.tipo = item.tipo_rep_id?.desc_tprep ?? "";
        data.autor = item.created_by ?? "";
        data.cliente = item.cliente_id ?? "";

        // Crear un objeto Date con la fecha en formato ISO
        if (item.createdate !== null) {
          const date = new Date(item.created_date);

          // Extraer el día, mes y año
          const day = String(date.getDate()).padStart(2, '0'); // Asegura dos dígitos
          const month = String(date.getMonth() + 1).padStart(2, '0'); // Mes entre 1 y 12
          const year = date.getFullYear();

          // Modificar el created_date al formato DD/MM/AAAA
          data.fecha = `${day}/${month}/${year}`;
        }
        data.data = item;
        return data;
      });

      // Establecer las filas con las fechas modificadas
      setRows(modifiedItems);
      setPageCount(res.data.totalPages);
      setItemsCount(res.data.totalItems);
    });
  }

  const fetchReportesBySuccess = () => {
    getAllReportes(paginaActual, orderBy, searchTerm).then((res) => {
      const modifiedItems = res.data.items.map(item => {
        let data = {};

        data.tipo = item.tipo_rep_id?.desc_tprep ?? "";
        data.autor = item.created_by ?? "";
        data.cliente = item.cliente_id ?? "";

        // Crear un objeto Date con la fecha en formato ISO
        if (item.createdate !== null) {
          const date = new Date(item.created_date);

          // Extraer el día, mes y año
          const day = String(date.getDate()).padStart(2, '0'); // Asegura dos dígitos
          const month = String(date.getMonth() + 1).padStart(2, '0'); // Mes entre 1 y 12
          const year = date.getFullYear();

          // Modificar el created_date al formato DD/MM/AAAA
          data.fecha = `${day}/${month}/${year}`;
        }
        data.data = item;
        return data;
      });

      if (paginaActual > res.data.totalPages) setPaginaActual(paginaActual - 1)
      if (pageCount < res.data.totalPages) setPaginaActual(res.data.totalPages)

      // Establecer las filas con las fechas modificadas
      setRows(modifiedItems);
      setPageCount(res.data.totalPages);
      setItemsCount(res.data.totalItems);
    });
  }

  useEffect(() => {
    fetchReportes();
  }, [])

  useEffect(() => {
    fetchReportes();
  }, [paginaActual, orderBy, searchTerm])

  const handleOrder = (value) => {
    let auxValue;

    if (value === "Apellido") auxValue = "lastName";
    if (value === "Nombre") auxValue = "firstName";
    if (value !== "Apellido" && value !== "Nombre") auxValue = value;

    let valor;
    if (orderBy === "" || orderBy.replace("-", "").length !== auxValue.length) {
      valor = auxValue;
    } else {
      valor = orderBy;
    }

    if (valor.indexOf("-") > -1) {
      const updatedValue = valor.replace("-", "");
      setOrderBy(updatedValue);
    } else {
      const updatedValue = "-" + valor;
      setOrderBy(updatedValue);
    }
  };

  const handleClickButtonHeader = () => {
    setDatosModal("");
    setModalStatus(true);
  }
  const handleClickButonTable = (value, valueRow) => {
    if (value !== "Eliminar") {
      setModalStatus(true);
      setDatosModal({ button: value.toLowerCase(), datos: valueRow });
    } else {

    }
  }
  return (
    <Styled>
      <Modal
        datos={datosModal}
        isOpen={statusModal}
        type={"reportes"}
        onClose={({ msj, status }) => {
          setSuccess(false);
          setError(false);
          setKey(key + 1);

          if (status === "success") {
            setSuccess(true);
            setMsjToast(msj);
            fetchReportesBySuccess();
          }

          if (status === "error") {
            setError(true);
            setMsjToast(msj);
          }

          setModalStatus(false);
        }}
      />
      <HeaderTable
        styledHead="cfg"
        proveedores
        title="Reportes"
        labelButton={"Nuevo Reporte"}
        valueBuscador={(value) => {
          setSearchTerm(value)
          setPaginaActual(1)
        }}
        onClickButton={() => { handleClickButtonHeader() }}
        search
      />

      <Tables
        typeTable={"prop"}
        columns={columns}
        cRows={20}
        rows={rows}
        totalRows={itemsCount}
        pagination={true}
        onClickSwitch={(value) => { handleOrder(value) }}
        page={(e) => setPaginaActual(e + 1)}
        pages={pageCount}
        actualpage={paginaActual}
        //valueBuscador={valueBuscador}
        buttons={buttonsReportes}
        onClickButtonTable={(value, valuesRow) => { handleClickButonTable(value, valuesRow) }}
      />

    </Styled>
  )
}

export default Reportes

const Styled = styled.div`
  padding: 20px;
  margin: auto;
  .styledTables{
    //height: 100vh;
  }
`