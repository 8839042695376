import React, { useEffect, useReducer, useState } from 'react';
import { StyledModalcomponentClientesExternos } from '../../../Styled';
import { TextField } from '@mui/material';
import CustomButton from '../../../../Button/CustomButton.tsx';
import Card from './Items/Card';
import CarouselCustom from '../../../../Carousel/CarouselCustom.tsx';
import Tables from '../../../../Tables/Tables.jsx';
import Modal from '../../../Modal.jsx';
import { imprimirMuestras, lookupDiagnostico } from '../../../../../api/Clientes.js';
import Preloader from '../../../../Preloader/Preloader.jsx';
import { RenderImgStyled } from '../../../../Tables/TablesStyled.js';
import Input from '../../../../Input/Input.jsx';
import { colors } from '../../../../../assets/styles/theme.ts';

const ViewMuestras = (props) => {
  const [viewData, setViewData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [statusModal, setModalStatus] = useState(false);
  const [open, setOpen] = useState(false);
  const [form, setForm] = useReducer((state, action) => {
    switch (action.type) {
      case 'SET_FIELD':
        return { ...state, [action.field]: action.value };
      default:
        return state;
    }
  }, {
    diagnostico: '',
    apellido: '',
    sugerencia: '',
  });

  const [columns, setColumns] = useState([
    { id: "analisis", label: "Análisis" },
    { id: "resultado", label: "Resultado" },
    { id: "unidad", label: "Unidad" }
  ]);

  const [preColumnasMuestras, setPreColumnasMuestras] = useState(null);
  const [resultados, setResultados] = useState([]);
  const [selectedEstadoFinal, setSelectedEstadoFinal] = useState(null);

  const rows = [
    { analisis: "Contenido de Agua y Sedimentos" },
  ]

  const handleChange = (e, nameReactSelect) => {
    if (e.target) {
      const { name, value, checked, type } = e.target;
      const fieldValue = type === 'checkbox' ? checked : value;
      setForm({ type: 'SET_FIELD', field: name, value: fieldValue });
    } else {
      const value = e.value;
      const name = nameReactSelect;
    }
  };

  const setColumnasMuestras = (muestras) => {
    //console.log(muestras);

    // Crear un arreglo base con las columnas iniciales
    let baseColumns = [
      { id: "analisis", label: "Análisis" },
      { id: "resultado", label: "Resultado" },
      { id: "unidad", label: "Unidad" },
    ];

    // Agregar columnas dependiendo de `muestras.length`
    if (muestras.length === 1) {
      baseColumns.push({ id: "Aunidad", label: <RenderImgStyled img={`data:image/png;base64,${muestras[0].estadoFinalFile.imagen_efm}`} label={muestras[0].codigo_mue} /> });
    } else if (muestras.length === 2) {
      baseColumns.push(
        { id: "Aunidad", label: <RenderImgStyled img={`data:image/png;base64,${muestras[0].estadoFinalFile.imagen_efm}`} label={muestras[0].codigo_mue} /> },
        { id: "Bunidad", label: <RenderImgStyled img={`data:image/png;base64,${muestras[1].estadoFinalFile.imagen_efm}`} label={muestras[1].codigo_mue} /> },
      );
    } else if (muestras.length === 3) {
      baseColumns.push(
        { id: "Aunidad", label: <RenderImgStyled img={`data:image/png;base64,${muestras[0].estadoFinalFile.imagen_efm}`} label={muestras[0].codigo_mue} /> },
        { id: "Bunidad", label: <RenderImgStyled img={`data:image/png;base64,${muestras[1].estadoFinalFile.imagen_efm}`} label={muestras[1].codigo_mue} /> },
        { id: "Cunidad", label: <RenderImgStyled img={`data:image/png;base64,${muestras[2].estadoFinalFile.imagen_efm}`} label={muestras[2].codigo_mue} /> },
      );
    }

    // Actualizar las columnas con el nuevo conjunto
    //console.log(baseColumns);
    setColumns(baseColumns);
  };


  useEffect(() => {
    setIsLoading(true);

    lookupDiagnostico(props.datos.datos.id)
    .then((res) => {
      setViewData(res.data);
      setResultados(res.data.resultado);
      setColumnasMuestras(res.data.muestras_anteriores);
      setPreColumnasMuestras(res.data.muestras_anteriores);
      setSelectedEstadoFinal(res.data.estado_final);

      setIsLoading(false);
    })

  },[])

  const handleImprimirMuestraEspecifica = () => {
    const bodyImprimir = [props.datos.datos.id];
  
    imprimirMuestras(bodyImprimir)
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data], { type: "application/pdf" }));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "muestra-" + bodyImprimir[0] + ".pdf"); // Nombre del archivo
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        console.error("Error al descargar el PDF:", error);
      });
  };

  return (
    <StyledModalcomponentClientesExternos sx={{
      width: "70%",
      height: "80%",
      overflowY: "auto",
      '@media (max-width: 768px)': {
        width: "90%",
        height: "80%",
        overflowY: "auto",
      },
    }}>
      <Preloader
        title="Cargando..."
        subtitle={"Espere unos Segundos por favor..."}
        loading={isLoading}
      />
      <div className="viewMuestras">
        <div className="headerDiv">
          <div className="divCard">
            <Card datos={viewData ?? ""}
              onChangeInput={(e) => { handleChange(e) }}
            />
          </div>
          <div className="divCarousel">
            <div className="divButtons">
              <CustomButton
                onClick={props.onClick}
                label={"Cancelar"}
                variant="contained"
                className={`buttonColorDisabled`}

              />
              <CustomButton
                onClick={handleImprimirMuestraEspecifica}
                label={"Imprimir"}
                variant="contained"
                className={`buttonColorDefault`}
              />

            </div>
            <CarouselCustom images={viewData?.imagenes ?? []}/>
          </div>
        </div>
        <div className="divInputsViewMuestras">
          <TextField
            id="outlined-multiline-flexible"
            label="Diagnóstico"
            placeholder="Diagnóstico"
            name={"diagnostico"}
            multiline
            maxRows={4}
            onChange={handleChange}
            value={viewData?.diagnostico_mue ?? ""}
          />
          <TextField
            id="outlined-multiline-flexible"
            label="Sugerencias"
            placeholder="Sugerencias"
            name={"sugerencia"}
            multiline
            maxRows={4}
            onChange={handleChange}
            value={viewData?.sugerencias_mue ?? ""}
          />

        </div>
        <div className="divTable">
        <Tables
                columns={columns}
                rows={resultados.map(resultado => ({
                  analisis: resultado.analisis.description,
                  resultado: <Input value={resultado.valor_res}
                    onChange={(e) => {
                      //handleChangeResultado(e.target.value, resultado);
                      //console.log(columns[4]?.label.props.label)
                      //console.log(resultado.valor_res_ant.find((res) => res.codigo_mue === columns[4]?.label.props.label))
                    }}
                    sx={resultado.notificado_sn_res === 'S' && selectedEstadoFinal ? {
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: `3px solid ${selectedEstadoFinal.color_efm}`,
                        },
                        "&:hover fieldset": {
                          border: `3px solid ${selectedEstadoFinal.color_efm}`, 
                          filter: "brightness(1.1)", 
                        },
                      }
                    } : {
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: `3px solid ${colors.turquoise}`,
                        },
                        "&:hover fieldset": {
                          border: `3px solid ${colors.turquoise}`, 
                          filter: "brightness(1.1)", 
                        },
                       
                      }
                    }}
                  />,
                  unidad: resultado.unidad.description,


                  Aunidad:
                    resultado.valor_res_ant.find((res) => res.codigo_mue === columns[3]?.label.props.label)?.notificado_sn_res === 'S' ?
                      <div style={{ border: `2px solid ${preColumnasMuestras[0].estadoFinalFile.color_efm}`, padding: "15px 5px", borderRadius: "8px", boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)" }}>
                        {resultado.valor_res_ant.find((res) => res.codigo_mue === columns[3]?.label.props.label)?.valor_res}
                      </div>
                      :
                      resultado.valor_res_ant.find((res) => res.codigo_mue === columns[3]?.label.props.label)?.valor_res,


                  Bunidad: resultado.valor_res_ant.find((res) => res.codigo_mue === columns[4]?.label.props.label)?.notificado_sn_res === 'S' ?
                    <div style={{ border: `2px solid ${preColumnasMuestras[1].estadoFinalFile.color_efm}`, padding: "15px 5px", borderRadius: "8px", boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)" }}>
                      {resultado.valor_res_ant.find((res) => res.codigo_mue === columns[4]?.label.props.label)?.valor_res}
                    </div> :
                    resultado.valor_res_ant.find((res) => res.codigo_mue === columns[4]?.label.props.label)?.valor_res,


                  Cunidad: resultado.valor_res_ant.find((res) => res.codigo_mue === columns[5]?.label.props.label)?.notificado_sn_res === 'S' ?
                    <div style={{ border: `2px solid ${preColumnasMuestras[2].estadoFinalFile.color_efm}`, padding: "15px 5px", borderRadius: "8px", boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)" }}>
                      {resultado.valor_res_ant.find((res) => res.codigo_mue === columns[5]?.label.props.label)?.valor_res}
                    </div> :
                    resultado.valor_res_ant.find((res) => res.codigo_mue === columns[5]?.label.props.label)?.valor_res,
                }))}
                typeTable={'prop'}
                pagination={false}
                tableColor={true}
              />
        </div>
      </div>
      <Modal
        // datos={data}
        // labelModal={labelModal}
        isOpen={statusModal}
        onClose={() => setModalStatus(false)}
        type={"modalDuplicate"}
      />
    </StyledModalcomponentClientesExternos>
  )
}

export default ViewMuestras