import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import Modal from '../../components/Modal/Modal';
import HeaderTable from "../../components/HeaderTable/HeaderTable";
import Tables from '../../components/Tables/Tables';
import { buttonsUsuariosSuperAdmin } from '../../components/ButtonsTable/ButtonsTable';
import { Add } from '@mui/icons-material';
import { getAllUsers } from '../../api/Clientes';
import Toast from '../../components/Toast/Toast';

const columns = [
  { id: "lastName", label: "Apellido" },
  { id: "firstName", label: "Nombre" },
  { id: "clienteId", label: "Cliente" },
  { id: "plantaId", label: "Plantas" },
  { id: "buttons" },
];

const rows = [
  { apellido: "apellido", nombre: "nombre", cliente: "cliente", plantas: "plantas" },
];

const buttonsHeadTable = [
  {
    label: "Crear Nuevo Usuario",
    classname: "buttonHeaderTable",
    size: "medium",
    icon: <Add />,
  },
];

const Usuarios = () => {
  const [statusModal, setModalStatus] = useState(false);
  const [datosModal, setDatosModal] = useState("");
  const [typeModal, setTypeModal] = useState("");

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [msjToast, setMsjToast] = useState("");
  const [key, setKey] = useState(0);

  const [users, setUsers] = useState([]);

  const [orderBy, setOrderBy] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [paginaActual, setPaginaActual] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [itemsCount, setItemsCount] = useState(0);

  const handleClickButtonHeader = () => {
    setModalStatus(true);
    setDatosModal({ button: "Crear" });
    setTypeModal("usuariosSuperAdmin");
  }

  const handleOrder = (value) => {
    let auxValue;

    if(value === "Apellido") auxValue = "lastName";
    if(value === "Nombre") auxValue = "firstName";
    if(value !== "Apellido" && value !== "Nombre") auxValue = value;

    let valor;
    if (orderBy === "" || orderBy.replace("-", "").length !== auxValue.length) {
      valor = auxValue;
    } else {
      valor = orderBy;
    }

    if (valor.indexOf("-") > -1) {
      const updatedValue = valor.replace("-", "");
      setOrderBy(updatedValue);
    } else {
      const updatedValue = "-" + valor;
      setOrderBy(updatedValue);
    }
  };

  const handleClickButonTable = (value, valueRow) => {
    //console.log(value);

    if (value !== "Eliminar") {
      setModalStatus(true);
      setDatosModal({ button: value.toLowerCase(), datos: valueRow });
      setTypeModal("usuariosSuperAdmin");
    } else {
      setModalStatus(true);
      setDatosModal({ button: value.toLowerCase(), datos: valueRow });
      setTypeModal("deleteUsuariosSuperAdmin")
    }
  };

  useEffect(() => {
    fetchUsers();
  },[])

  useEffect(() => {
    fetchUsers();
  }, [paginaActual, orderBy, searchTerm])

  const fetchUsers = () => {
    getAllUsers(paginaActual,orderBy,searchTerm).then( (res) => {

      setUsers(res.data.items);
      setPageCount(res.data.totalPages);
      setItemsCount(res.data.totalItems);
    })
  }

  const fetchUsersBySuccess = () => {
    getAllUsers(paginaActual,orderBy,searchTerm).then( (res) => {

      if(paginaActual > res.data.totalPages) setPaginaActual(paginaActual - 1)
      if(pageCount < res.data.totalPages) setPaginaActual(res.data.totalPages)

      setUsers(res.data.items);
      setPageCount(res.data.totalPages);
      setItemsCount(res.data.totalItems);
    })
  }

  return (
    <Styled>

      <div classname="toast">
              {success ? (
                <Toast key={key} title={msjToast} close={() => setSuccess(false)} />
              ) : null}
              {error ? (
                <Toast
                  key={key}
                  type={"error"}
                  title={msjToast}
                  close={() => setError(false)}
                />
              ) : null}
      </div>

      <Modal
        datos={datosModal}
        isOpen={statusModal}
        onClose={({ msj, status }) => {
          setSuccess(false);
          setError(false);
          setKey(key + 1);

          if(status === "success") {
            setSuccess(true);
            setMsjToast(msj);
            fetchUsersBySuccess();
          } 

          if(status === "error"){
            setError(true);
            setMsjToast(msj);
          }
          

          setModalStatus(false);
        }}
        type={typeModal}
      />
      <HeaderTable
        styledHead="clientes"
        muestras
        title="Usuarios"
        valueBuscador={(value) => {
          setSearchTerm(value)
          setPaginaActual(1)
        }}
        datosButtons={buttonsHeadTable}
        onClickButton={() => {
          handleClickButtonHeader();
        }}
      // value={valueSearch}
      // search
      // isClearable={() => { setValueSearch("") }}
      />

      <Tables
        typeTable={"prop"}
        columns={columns}
        rows={users}
        cRows={20}
        pagination={true}
        // valueBuscador={valueBuscador}
        buttons={buttonsUsuariosSuperAdmin}
        onClickButtonTable={(value, valuesRow) => {
          handleClickButonTable(value, valuesRow);
        }}
        page={(e) => setPaginaActual(e + 1)}
        pages={pageCount}
        totalRows={itemsCount}
        actualpage={paginaActual}
        onClickSwitch={(value) => {
          handleOrder(value);
        }}
      />

    </Styled>
  )
}

export default Usuarios;
const Styled = styled.div`
  padding: 20px;
  margin: auto;

  .styledTables {
    //height: 100vh;
  }
`;
