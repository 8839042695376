import React, { useEffect, useState } from 'react'
import {
  DashBoardFooter,
  BoxLabelFooter,
  TitleFooter,
  DescriptionFooter,
  BoxTableFooter,
} from '../../DashboardPlantasStyled';
import Tables from "../../../../../../components/Tables/Tables";
import { getIntervenciones } from '../../../../../../api/Inicio';
const DashboardFooter = () => {
  const [rows, setRows] = useState("");

  const columns = [
    { id: "fecha", label: "Fecha" },
    { id: "tipo", label: "Tipo Intervención" },
    { id: "nombre", label: "Intervención" },
    { id: "equipo", label: "Equipo" },
    { id: "planta", label: "Planta" }
  ]

  const handleClickButonTable = (value, valueRow) => {
    console.log(value, valueRow);
  }

  useEffect(() => {
    const fetchIntervenciones = async () => {
      try {
        const res = await getIntervenciones();
        setRows(
          res.data.map((res) => {
            const fechaFormat = res.fecha_inte;
            const fecha = new Date(fechaFormat).toLocaleString()
            return {
              fecha: fecha,
              tipo: res.tipo.description,
              nombre: res.desc_inte,
              equipo: res.equipo.desc_equi,
              planta: res.equipo.planta.description
            };
          })
        );
      } catch (error) {
        console.error(error);
      }
    };

    fetchIntervenciones();
  }, []);

  return (
    <DashBoardFooter>
      <BoxLabelFooter>
        <TitleFooter>Intervenciones</TitleFooter>
        <DescriptionFooter>Aquí podrá consultar las últimas 3 intervenciones registradas.
          Para crear nuevas intervenciones o realizar consultas, presione  la opción “Intervenciones”  que se encuentra en la cabecera
        </DescriptionFooter>
      </BoxLabelFooter>
      <BoxTableFooter>
        <Tables
          typeTable={"prop"}
          columns={columns}
          rows={rows}
          cRows={6}
          pagination={false}
          onClickButtonTable={(value, valuesRow) => { handleClickButonTable(value, valuesRow) }}
        />
      </BoxTableFooter>
    </DashBoardFooter>
  )
}

export default DashboardFooter