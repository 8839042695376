import RenderImg from "../RenderImg/RenderImg.tsx";
import Inicio from "../../assets/Icons/Nav/Inicio.svg";
import Clientes from "../../assets/Icons/Nav/Clientes.svg"
import Configuracion from "../../assets/Icons/Nav/Configuracion.svg"
import Muestras from "../../assets/Icons/Nav/Muestras.svg"
import Proveedores from "../../assets/Icons/Nav/Proveedores.svg"
import Reportes from "../../assets/Icons/Nav/Reportes.svg"
import Plant from '@mui/icons-material/FactoryOutlined';
import Unidades from '../../assets/Icons/Nav/IconsCfg/Vector.png';
import Tunidades from '../../assets/Icons/Nav/IconsCfg/arrange-bring-to-front.png';
import Analisis from '../../assets/Icons/Nav/IconsCfg/beaker-check.png';
import TAnalisis from '../../assets/Icons/Nav/IconsCfg/beaker-question.png';
import ConjuntoAnalisis from '../../assets/Icons/Nav/IconsCfg/Vectordd.png';
import TipoConjuntoAnalisis from '../../assets/Icons/Nav/IconsCfg/ungroup.png';
import Lubricantes from '../../assets/Icons/Nav/IconsCfg/Oil.png';
import TLubricantes from '../../assets/Icons/Nav/IconsCfg/water-alert.png';
import EstadosFinales from '../../assets/Icons/Nav/IconsCfg/tag-plus.png';
import TEventos from '../../assets/Icons/Nav/IconsCfg/bell-alert.png';
import Cfg from '../../assets/Icons/Nav/IconsCfg/Cogs.png';
import Metodos from '../../assets/Icons/Nav/IconsCfg/arrow-expand-all.png';
import CausasDeFalla from '../../assets/Icons/Nav/IconsCfg/water-boiler-alert.png';
import TiposDeFalla from '../../assets/Icons/Nav/IconsCfg/water-boiler-alert.png';
import TemplateEmail from "../../assets/Icons/Nav/TemplateEmails.svg";
import ReportesIcon from '../../assets/Icons/Nav/IconsReportes/1.png';
import PuntosDeToma from '../../assets/Icons/Nav/IconsReportes/2.png';
import Plantas from '../../assets/Icons/Nav/IconsReportes/3.png';
import Intervenciones from '../../assets/Icons/Nav/IconsReportes/4.png';

import OrdenDeTrabajo from '../../assets/Icons/Nav/IconsMuestras/1.png';
import ImportacionResultados from '../../assets/Icons/Nav/IconsMuestras/2.png';
import MuestrasIcon from '../../assets/Icons/Nav/IconsMuestras/3.png';
import ImportacionDeImagenes from '../../assets/Icons/Nav/IconsMuestras/4.png';
import CargaManualDeResultados from '../../assets/Icons/Nav/IconsMuestras/5.png';

import Equipos from '../../assets/Icons/Nav/IconsClientes/c1.png';
//Clientes Externos
import InfoMuestras from "../../assets/Icons/Nav/Muestras.svg";
import IntervencionesOutlined from "../../assets/Icons/Nav/ClientesExternos/IntervencionesOutlined.svg";
import Ayuda from "../../assets/Icons/Nav/ClientesExternos/Ayuda.svg";

export const datos = [
  {
    SUPERADMIN: {
      buttons: [
        {
          label: "Configuración",
          icon: Configuracion
        },
        {
          label: "Usuarios",
          icon: Proveedores
        },
      ],
      configuración: [
        {
          icon: Configuracion,
          label: "Configuración administrador",
        },
        {
          icon: Tunidades,
          label: "Templates Emails",
          subLabel: "Templates Emails"
        },
        {
          icon: OrdenDeTrabajo,
          label: "Estados Ordenes de Trabajo",
          subLabel: "Estados Ordenes de Trabajo"
        },
      ]
    },
    TENANT: {
      buttons: [
        {
          label: "Inicio",
          icon: Inicio
        },

        {
          label: "Muestras",
          icon: Muestras
        },
        {
          label: "Clientes",
          icon: Clientes
        },
        {
          label: "Proveedores",
          icon: Proveedores
        },
        {
          label: "Reportes",
          icon: Reportes
        },
        {
          label: "Configuración",
          icon: Configuracion
        },
      ],
      configuración: [
        {
          icon: Unidades,
          label: "Unidades",
          subLabel: "Alta, edición, eliminación de unidades de medida"
        },
        {
          icon: Tunidades,
          label: "Tipos de Unidades",
          subLabel: "Alta, edición, eliminación de tipos de unidades de medida"
        },
        {
          icon: Analisis,
          label: "Análisis",
          subLabel: "Alta, edición, eliminación de análisis"
        },
        {
          icon: TAnalisis,
          label: "Tipos de Análisis",
          subLabel: "Alta, edición, eliminación de tipos de análisis, utilizados para agrupar análisis en los informes"
        },
        {
          icon: ConjuntoAnalisis,
          label: "Conjunto de Análisis",
          subLabel: "Alta, edición, eliminación de conjuntos de análisis"
        },
        {
          icon: TipoConjuntoAnalisis,
          label: "Tipos de Conjunto de Análisis",
          subLabel: "Alta, edición, eliminación de tipos de conjuntos de análisis"
        },
        {
          icon: Lubricantes,
          label: "Lubricantes",
          subLabel: "Alta, edición, eliminación de los lubricantes utilizados en los equipos"
        },
        {
          icon: TLubricantes,
          label: "Tipos de Lubricantes",
          subLabel: "Alta, edición, eliminación de tipos de lubricantes"
        },
        {
          icon: EstadosFinales,
          label: "Estados Finales",
          subLabel: "Alta, edición, eliminación de los estados que manejaran las muestas"
        },
        {
          icon: TEventos,
          label: "Tipos de Eventos",
          subLabel: "Alta, edición, eliminación de tipos de eventos, utilizados en la carga de intervenciones"
        },
        {
          icon: Cfg,
          label: "Componentes",
          subLabel: "Alta, edición, eliminación de los componentes"
        },
        {
          icon: Metodos,
          label: "Métodos",
          subLabel: "Alta, edición, eliminación de métodos para realizar análisis"
        },
        {
          icon: CausasDeFalla,
          label: "Causas de Falla",
          subLabel: "Alta, edición, eliminación de las causas de fallas de equipos."
        },
        {
          icon: TiposDeFalla,
          label: "Tipos de Falla",
          subLabel: "Alta, edición, eliminación de los Tipos de fallas de equipos."
        },
      ],
      clientes: [
        {
          icon: Reportes,
          label: "Clientes",
          subLabel: "Alta, edición, eliminación de clientes del laboratorio."
        },
        {
          icon: PuntosDeToma,
          label: "Puntos de Toma",
          subLabel: "Alta, edición, eliminación de los puntos de toma de cada equipo"
        },
        {
          icon: Plantas,
          label: "Plantas",
          subLabel: "Alta, edición, eliminación de las plantas de cada cliente."
        },
        {
          icon: Intervenciones,
          label: "Intervenciones",
          subLabel: "Alta, edición, eliminación de intervenciones realizadas sobre los equipos."
        }
        ,
        {
          icon: Equipos,
          label: "Equipos",
          subLabel: "Alta, edición, eliminación de los equipos de cada planta."
        }
      ],
      muestras: [
        {
          "icon": OrdenDeTrabajo,
          "label": "Ordenes de Trabajo",
          "subLabel": "Creación y seguimiento de OT"
        },
        {
          "icon": ImportacionResultados,
          "label": "Importación de Resultados",
          "subLabel": "Importación de archivo con resultados de muestras"
        },
        {
          "icon": MuestrasIcon,
          "label": "Muestras",
          "subLabel": "Registro, diagnóstico, consulta de muestras"
        },
        {
          "icon": ImportacionDeImagenes,
          "label": "Importación de Imágenes",
          "subLabel": "Importación de archivo con imágenes de muestras"
        },
        {
          "icon": CargaManualDeResultados,
          "label": "Carga Manual de Resultados",
          "subLabel": "Carga manual de resultados de muestras"
        }
      ],
      proveedores: [
        {
          "icon": Proveedores,
          "label": "Proveedores",
          "subLabel": "Proveedores"
        },
      ]
    },
    CLIENTE: {
      buttons: [
        {
          label: "Inicio",
          icon: Inicio
        },
        {
          label: "Informes de Muestras",
          icon: InfoMuestras
        },
        {
          label: "Intervenciones",
          icon: IntervencionesOutlined
        },
        {
          label: "Reportes",
          icon: Reportes
        },
        {
          label: "Ayuda",
          icon: Ayuda
        },
      ],
      reportes: [
        {
          icon: ReportesIcon,
          label: "Reporte 1",
          subLabel: "Alta, edición, eliminación de clientes del laboratorio"
        },
        {
          icon: PuntosDeToma,
          label: "Puntos de Toma",
          subLabel: "Alta, edición, eliminación de los puntos de toma de cada equipo"
        },
        {
          "icon": Plantas,
          "label": "Plantas",
          "subLabel": "Alta, edición, eliminación de las plantas de cada cliente"
        },
        {
          "icon": Intervenciones,
          "label": "Intervenciones",
          "subLabel": "Alta, edición, eliminación de intervenciones realizadas sobre los equipos"
        }
      ],
    },
    PLANTA: {
      buttons: [
        {
          label: "Inicio",
          icon: Inicio
        },
        {
          label: "Muestras",
          icon: Muestras
        },
        {
          label: "Plantas",
          iconMaterial: <Plant />
        },
        {
          label: "Reportes",
          icon: Reportes
        },
      ],
      reportes: [
        {
          icon: ReportesIcon,
          label: "Reporte 1",
          subLabel: "Alta, edición, eliminación de clientes del laboratorio"
        },
        {
          icon: PuntosDeToma,
          label: "Puntos de Toma",
          subLabel: "Alta, edición, eliminación de los puntos de toma de cada equipo"
        },
        {
          "icon": Plantas,
          "label": "Plantas",
          "subLabel": "Alta, edición, eliminación de las plantas de cada cliente"
        },
        {
          "icon": Intervenciones,
          "label": "Intervenciones",
          "subLabel": "Alta, edición, eliminación de intervenciones realizadas sobre los equipos"
        }
      ],
      muestras: [
        {
          "icon": OrdenDeTrabajo,
          "label": "Ordenes de Trabajo",
          "subLabel": "Creación y seguimiento de OT"
        },
        {
          "icon": ImportacionResultados,
          "label": "Importación de Resultados",
          "subLabel": "Importación de archivo con resultados de muestras"
        },
        {
          "icon": MuestrasIcon,
          "label": "Muestras",
          "subLabel": "Registro, diagnóstico, consulta de muestras"
        },
        {
          "icon": ImportacionDeImagenes,
          "label": "Importación de Imágenes",
          "subLabel": "Importación de archivo con imágenes de muestras"
        },
        {
          "icon": CargaManualDeResultados,
          "label": "Carga Manual de Resultados",
          "subLabel": "Carga manual de resultados de muestras"
        }
      ],
      plantas: [
        {
          icon: Plantas,
          label: "Plantas",
          subLabel: "Alta, edición, eliminación de los puntos de toma de cada equipo"
        },

        {
          icon: PuntosDeToma,
          label: "Puntos de Toma",
          subLabel: "Alta, edición, eliminación de los puntos de toma de cada equipo"
        },

        {
          icon: Intervenciones,
          label: "Intervenciones",
          subLabel: "Alta, edición, eliminación de intervenciones realizadas sobre los equipos."
        }
        ,
        {
          icon: Equipos,
          label: "Equipos",
          subLabel: "Alta, edición, eliminación de los equipos de cada planta."
        }
      ],
    }
  }
]