import React from 'react'
import { colors, fonts } from "../../assets/styles/theme.ts"
import { Typography, styled, Box } from '@mui/material'
import Limsoil from "../../assets/Login/LimsOil.png";
const NoAutorizado = () => {
  return (
    <BoxStyled>
      <img src={Limsoil} alt="limsoil" width="100px" style={{ margin: "auto" }} />
      <TypographyStyled sx={{ color: colors.blueDark, fontFamily: fonts.primary }}>No tienes permisos para acceder a esta página.</TypographyStyled>
    </BoxStyled>
  )
}

const BoxStyled = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 10px;
` ;

const TypographyStyled = styled(Typography)`
  color: ${colors.blueDark};
  font-family: ${fonts.primary};
  font-size: 20px;
  text-align: center;
` ;

export default NoAutorizado