import React, { useCallback, useRef } from "react";
import { useDropzone } from "react-dropzone";
import styled from "styled-components";
import CustomButton from "../Button/CustomButton.tsx";
import { Divider } from "@mui/material";
import { DeleteOutlined } from "@mui/icons-material";
import { colors } from "../../assets/styles/theme.ts";

const DropZoneExcel = (props) => {
  const inputRef = useRef(null);

  const onDrop = useCallback((acceptedFiles) => {
    const processFilesToBinary = (files) =>
      Promise.all(
        files.map(
          (file) =>
            new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.onload = () => {
                if (reader.result) {
                  resolve({
                    file: reader.result, // Aquí es un ArrayBuffer (binario)
                    nombre: file.name,
                    tipo: file.type,
                  });
                } else {
                  reject(new Error(`Error leyendo el archivo: ${file.name}`));
                }
              };
              reader.onerror = reject;
              reader.readAsArrayBuffer(file); // Leer como binario
            })
        )
      );

    processFilesToBinary(acceptedFiles)
      .then((filesWithBinary) => {
        if (props.onUploadFiles) {
          props.onUploadFiles(filesWithBinary); // Enviar el binario
        }
      })
      .catch((error) => {
        console.error("Error al procesar archivos a binario", error);
      });
  }, [props]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    noClick: true,
    accept: [
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // .xlsx
      "application/vnd.ms-excel", // .xls
    ],
  });

  const handleDelete = (file, index) => {
    if (props.onClickDelete) {
      props.onClickDelete(file, index);
    }
  };

  const openDialog = () => {
    inputRef.current.click();
  };

  return (
    <Styled>
      <div {...getRootProps()} className="dropzone">
        <input {...getInputProps()} ref={inputRef} />
        <div className="divButton">
          <CustomButton
            label="Click para subir el archivo Excel con los resultados a importar"
            onClick={openDialog}
            className="button"
            size="medium"
          />
        </div>
        <div className="divFiles">
          {props.archivos.map((fileData, index) => (
            <div sx={{ display: "flex", flexDirection: "column" }} key={index}>
              <div className="divFile">
                <p className="labelFile">{fileData.nombre || "archivo - " + index}</p>
                <div className="divButtons">
                  <CustomButton
                    onClick={() => handleDelete(fileData, index)}
                    startIcon={<DeleteOutlined />}
                    className="buttonDelete"
                  />
                </div>
              </div>
              <Divider orientation="horizontal" sx={{ backgroundColor: colors.turquoise }} />
            </div>
          ))}
        </div>
      </div>
    </Styled>
  );
};

export default DropZoneExcel;

const Styled = styled.div`
  .dropzone {
    border: 2px dashed #afccd5;
    padding: 5px 0px;
    text-align: center;
    position: relative;
    z-index: 0;
  }
  .label {
    font-family: Inter;
    font-weight: bold;
    margin: 0px;
  }
  .divFiles {
    display: flex;
    flex-direction: column;
    padding: 10px;
    width: 50%;
    .divFile {
      display: flex;
      align-items: center;
      .labelFile {
        font-family: Inter;
        font-weight: bold;
        font-size: 15px;
        width: 80%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: start;
        margin: 0px;
      }
      .divButtons {
        display: flex;
        align-items: center;
        .buttonDelete {
          color: ${colors.turquoise};
          svg {
            font-size: 30px;
          }
        }
      }
    }
  }
  @media (max-width: 768px) {
    .divFiles {
      width: 90%;
      gap: 10px;
      .divFile {
        flex-direction: column;
        .labelFile {
          text-align: center;
        }
      }
    }
  }
`;
