import React, { useReducer, useState, useEffect } from "react";
import Input from "../../../../Input/Input.jsx";
import { useNavigate } from "react-router-dom";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Tables from "../../../../Tables/Tables.jsx";
import CustomButton from "../../../../Button/CustomButton.tsx";
import Modal from "../../../Modal.jsx";
import dayjs from "dayjs";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import InfoIcon from '@mui/icons-material/Info';
import RefreshIcon from "@mui/icons-material/Refresh";
import AddIcon from "@mui/icons-material/Add";
import ArticleIcon from "@mui/icons-material/Article";
import BiotechIcon from "@mui/icons-material/Biotech";
import { colors, fonts } from "../../../../../assets/styles/theme.ts";
import { Box, Typography } from "@mui/material";
import {
  getClienteOptions,
  getPlantaOptions,
  updateEquipos,
  createEquipos,
  getPlantabyCliente2,
  descargarExcelLleno,
} from "../../../../../api/Clientes.js";

import { StyledModalcomponentMuestras } from "../../../Styled.jsx";
import {
  avanzarOrdenTrabajo,
  createOrdenTrabajo,
  getEstadoOrdenesOptions,
  updateOrdenTrabajo,
  getAllOrdenTrabajoLog,
  getMuestraContadores,
} from "../../../../../api/Muestras.js";
import Preloader from "../../../../Preloader/Preloader.jsx";
import Toast from "../../../../Toast/Toast.jsx";
import { useAuth } from "../../../../../context/AuthContext.jsx";

const columnsDefaultCfg = [
  {
    id: "button",
    label: (
      <CustomButton
        onClick={() => {
          alert("refrescar");
        }}
        startIcon={<RefreshIcon />}
        label={"Refrescar"}
        variant="contained"
        size="small"
        className={`buttonColorDisabled`}
      />
    ),
  },
  { id: "cantidadmuestras", label: "Cantidad Muestras" },
  { id: "resultados", label: "Resultados (Cargados/Totales)" },
];

const columnsDefaultCfgDos = [
  { id: "fecha", label: "Fecha " },
  { id: "usuario", label: "usuario" },
  { id: "accion", label: "Acción" },
  { id: "estado", label: "estado" },
];

const datosRows1 = [
  {
    button: "Sin diagnóstico",
    cantidadmuestras: "0",
    resultados: "",
  },
  {
    button: "Sin sugerencias",
    cantidadmuestras: "0",
    resultados: "",
  },
  {
    button: "Sin estado final",
    cantidadmuestras: "0",
    resultados: "",
  },
  {
    button: "Sin fecha de análisis",
    cantidadmuestras: "0",
    resultados: "",
  },
  {
    button: "Sin  imágenes",
    cantidadmuestras: "0",
    resultados: "",
  },
  {
    button: "Sin resultados",
    cantidadmuestras: "0",
    resultados: "0",
  },
  {
    button: "Listas para enviar",
    cantidadmuestras: "0",
    resultados: "",
  },
  {
    button: "Enviadas",
    cantidadmuestras: "0",
    resultados: "",
  },
  {
    button: "Total de muestras",
    cantidadmuestras: "0",
    resultados: "",
  },
];

const datosRowsDos = [
  {
    fecha: "21/11/2023 09:55:51",
    usuario: "Maximiliano Bruno",
    accion: "Crea orden de trabajo",
    estado: "Sin Notificar",
  },
  {
    fecha: "21/11/2023 09:55:51",
    usuario: "Maximiliano Bruno",
    accion: "Crea orden de trabajo",
    estado: "Sin Notificar",
  },
  {
    fecha: "21/11/2023 09:55:51",
    usuario: "Maximiliano Bruno",
    accion: "Crea orden de trabajo",
    estado: "Sin Notificar",
  },
  {
    fecha: "21/11/2023 09:55:51",
    usuario: "Maximiliano Bruno",
    accion: "Crea orden de trabajo",
    estado: "Sin Notificar",
  },
  {
    fecha: "21/11/2023 09:55:51",
    usuario: "Maximiliano Bruno",
    accion: "Crea orden de trabajo",
    estado: "Sin Notificar",
  },
  {
    fecha: "21/11/2023 09:55:51",
    usuario: "Maximiliano Bruno",
    accion: "Crea orden de trabajo",
    estado: "Sin Notificar",
  },
  {
    fecha: "21/11/2023 09:55:51",
    usuario: "Maximiliano Bruno",
    accion: "Crea orden de trabajo",
    estado: "Sin Notificar",
  },
];

const OrdenesDeTrabajoModal = (props) => {
  const { token } = useAuth(); // Accede al token del contexto
  const [statusModal, setModalStatus] = useState(false);
  const [statusModalDiagMasivo, setModalStatusDiagMasivo] = useState(false);
  const [statusModalMuestras, setStatusModalMuestras] = useState(false);

  const [labelModalNoti, setLabelModalNoti] = useState("");
  const propsOrdenesdeTrabajo = (datosUnidades) => {
    const ver = datosUnidades?.button === "ver" ?? false;
    const controlPanel = datosUnidades?.button === "controlpanel" ?? false;
    const editar = datosUnidades?.button === "editar" ?? false;
    const datos = datosUnidades?.datos ?? [];
    return { ver, controlPanel, editar, datos };
  };
  const { ver, controlPanel, editar, datos } = propsOrdenesdeTrabajo(
    props.datos
  );
  const navigate = useNavigate();

  const [formCompleto, setFormCompleto] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [msjToast, setMsjToast] = useState("");
  const [clientes, setClientes] = useState([]);
  const [plantas, setPlantas] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOptioncli, setSelectedOptioncli] = useState(null);
  const [selectedOptionpla, setSelectedOptionpla] = useState(null);
  const [selectedOptionest, setSelectedOptionest] = useState(null);
  const [statusModal2, setModalStatus2] = useState(false);
  const [estados, setEstados] = useState([]);
  const [key, setKey] = useState(1);
  const [datosRows, setDatosRows] = useState([]);
  const [ordenTrabajoLog, setOrdenTrabajoLog] = useState(datosRows1);
  const [bloquearInput, setBloquearInput] = useState(
    ver || controlPanel ? true : false
  );
  const [fechaseleccionada, setFechaseleccionada] = useState(
    datos.fechaProgramada ? dayjs(datos.fechaProgramada) : null
  );

  //funcion crearLink
  const crearLink = (tipo, valor) => {
    let link = "";
    //  muestras/muestras?idcli=86&idpla=66&idot=77&ideq=81&sd=true&se=true&sf=true&si=true&sr=false&ss=false
    //  necesito todos los datos de la muestra para poder armar el link
    const {
      clienteId,
      plantaId,
      id,
      estadoComp: { final_sn_ef_ot }
    } = datos;
    let checks = {
      sd: tipo === "sd",
      se: tipo === "se",
      sf: tipo === "sf",
      si: tipo === "si",
      sr: tipo === "sr",
      ss: tipo === "ss",
    };
    console.log("datos: ", JSON.stringify(datos));
    let idEqui = null;
    link = `/muestras/muestras?idcli=${clienteId}&idpla=${plantaId}&idot=${id}&ideq=${idEqui}&sd=${checks.sd}&se=${checks.se}&sf=${checks.sf}&si=${checks.si}&sr=${checks.sr}&ss=${checks.ss}`;
    console.log("link: ", link);

    navigate(link);
  }

  const transformApiResponse = (apiResponse) => {
    return [
      {
        button: "Sin diagnóstico",
        cantidadmuestras: <div onClick={() => { crearLink("sd", apiResponse.sinDiagnostico) }} style={{ cursor: "pointer" }}>{apiResponse.sinDiagnostico.toString()}</div>,
        resultados: "",
      },
      {
        button: "Sin sugerencias",
        cantidadmuestras: <div onClick={() => { crearLink("ss", apiResponse.sinSugerencia) }} style={{ cursor: "pointer" }}>{apiResponse.sinSugerencia.toString()}</div>,
        resultados: "",
      },
      {
        button: "Sin estado final",
        cantidadmuestras: <div onClick={() => { crearLink("se", apiResponse.sinEstadoFinal) }} style={{ cursor: "pointer" }}>{apiResponse.sinEstadoFinal.toString()}</div>,
        resultados: "",
      },
      {
        button: "Sin fecha de análisis",
        cantidadmuestras: <div onClick={() => { crearLink("sf", apiResponse.sinFechaAnalisis) }} style={{ cursor: "pointer" }}>{apiResponse.sinFechaAnalisis.toString()}</div>,
        resultados: "",
      },
      {
        button: "Sin  imágenes",
        cantidadmuestras: <div onClick={() => { crearLink("si", apiResponse.sinImagenes) }} style={{ cursor: "pointer" }}>{apiResponse.sinImagenes.toString()}</div>,
        resultados: "",
      },
      {
        button: "Sin resultados",
        cantidadmuestras: <div onClick={() => { crearLink("sr", apiResponse.sinResultados) }} style={{ cursor: "pointer" }}>{apiResponse.sinResultados.toString()}</div>,
        resultados: <div onClick={() => { crearLink("sr", apiResponse.contadorResultados) }} style={{ cursor: "pointer" }}>{apiResponse.contadorResultados.toString()}</div>,
      },
      {
        button: "Listas para enviar",
        cantidadmuestras: <div  >{apiResponse.listasParaEnviar.toString()}</div>,
        resultados: "",
      },
      {
        button: "Enviadas",
        cantidadmuestras: apiResponse.enviadas.toString(),
        resultados: "",
      },
      {
        button: "Total de muestras",
        cantidadmuestras: apiResponse.totalMuestras.toString(),
        resultados: "",
      },
    ];
  };

  const [form, setForm] = useReducer(
    (state, action) => {
      switch (action.type) {
        case "SET_FIELD":
          return { ...state, [action.field]: action.value };
        default:
          return state;
      }
    },
    {
      id: datos.id ?? "",
      cliente: token.clienteId !== ""
        ? parseInt(token.clienteId, 10)
        : datos.clienteId
          ? datos.clienteId
          : "",
      plantaId: datos.plantaId ?? "",
      fecha: datos.fechaProgramada ? dayjs(datos.fechaProgramada) : null,
      codigo: datos.codigo ?? "",
      lote: datos.lote ?? "",
      denominacion: datos.denominacion ?? "",
      planta: token.plantaId === ""
        ? datos.plantaId ?? ""
        : token.plantaId.split(",").length === 1
          ? token.plantaId
          : datos.plantaId ?? "",
      clienteId: datos.clienteId ?? "",
      estadoCompleto: datos.estadoComp ?? "",
    }
  );
  useEffect(() => {
    if (fechaseleccionada || selectedOptionpla) {
      let cleanFormattedDate = "";

      if (fechaseleccionada) {
        cleanFormattedDate = new Intl.DateTimeFormat("es-ES", {
          month: "long",
          year: "numeric",
        })
          .format(new Date(fechaseleccionada))
          .replace(" de ", "/");
      }
      setForm({
        type: "SET_FIELD",
        field: "denominacion",
        value: `${selectedOptionpla ? `${selectedOptionpla.label} - ` : ''}${cleanFormattedDate}`
      });
    }
  }, [fechaseleccionada, selectedOptionpla]);

  useEffect(() => {

    console.log(form)

    // const campoValido = (campo) => form[campo] !== "";
    const campoValido = (campo) => form[campo] !== null && form[campo] !== "";
    const camposaValidar = [
      "cliente",
      "planta",
      "fecha",
      //"codigo",
      //"lote",
      "denominacion",
    ];
    const formularioCompleto = camposaValidar.every(campoValido);
    setFormCompleto(formularioCompleto);
    if (datos && !Array.isArray(datos)) {
      // console.log("formId: ", form.id);
      fetchOrdenTrabajoLog(form.id);
    }
  }, [form]);

  const fetchclientes = async () => {
    const res = await getClienteOptions();
    const unidades = res?.data?.map((item) => ({
      value: item.id,
      label: item.desc_cli,
    }));
    setClientes(unidades);

    if (datos) {
      const matchOption = unidades.find(
        (option) => option.label === datos.cliente
      );
      if (matchOption) {
        // console.log("matchOption", matchOption);
        setSelectedOptioncli(matchOption);
      } else if (unidades.length > 0 && editar) {
        setSelectedOptioncli(unidades[0]);
      }
    } else if (unidades.length > 0 && editar) {
      setSelectedOptioncli(unidades[0]);
    }
  };

  const fetchPlantas = async () => {
    const res = await getPlantaOptions();
    const unidades = res?.data?.map((item) => ({
      value: item.id,
      label: item.desc_pla,
    }));
    setPlantas(unidades);
    if (datos && !Array.isArray(datos)) {
      const matchOption = unidades.find(
        (option) => option.value === datos.plantaId
      );
      if (matchOption) {
        setSelectedOptionpla(matchOption);
      } else if (unidades.length > 0 && controlPanel) {
        let planta = { label: datos.planta, value: datos.plantaId };
        unidades.push(planta);
        setPlantas(unidades);
        setSelectedOptionpla(planta);
      } else {
        setSelectedOptionpla({ label: datos.planta, value: datos.plantaId, estado: "eliminado" });
      }
    } else if (unidades.length > 0 && editar) {
      setSelectedOptionpla(null);
    }
  };

  const fetchclientesAndPlantas = async () => {
    const res = await getClienteOptions();
    const allCLientes = res?.data?.map((item) => ({
      value: item.id,
      label: item.desc_cli,
    }));
    setClientes(allCLientes);

    if (datos.length === 0 && token.clienteId !== "") {

      const matchOption = allCLientes.find(
        (option) => option.value === parseInt(token.clienteId, 10)
      );

      if (matchOption) {
        setSelectedOptioncli(matchOption);

        getPlantabyCliente2(matchOption.value).then((res) => {
          if (res.data.length !== 0) {
            // Se crea el array de plantas
            const nuevasPlantas = res.data.map((item) => ({
              label: item.desc_pla,
              value: item.id,
            }));

            // Si plantaId no está vacío
            if (token.plantaId !== "") {
              // Si plantaId tiene un solo número, se configura el selectedOptionPla
              if (token.plantaId.split(",").length === 1) {
                const selectedPlant = nuevasPlantas.find(
                  (planta) => String(planta.value) === token.plantaId
                );
                setSelectedOptionpla(selectedPlant || null); // Si no se encuentra la planta, setea null
                setPlantas([selectedPlant])
              }
              // Si plantaId tiene varios números, se filtran las plantas
              else {
                const plantaIds = token.plantaId.split(",").map(id => parseInt(id.trim(), 10));
                const filteredPlantas = nuevasPlantas.filter((planta) =>
                  plantaIds.includes(planta.value)
                );
                setPlantas(filteredPlantas);
              }
            }
            // Si plantaId está vacío, solo se guardan todas las plantas
            else {
              setPlantas(nuevasPlantas);
            }
          }
        });
      }
    }

    if (datos && !Array.isArray(datos) && token.clienteId !== "") {
      // EDIT
      console.log("aca 2")

      const matchOption = allCLientes.find(
        (option) => option.value === parseInt(token.clienteId, 10)
      );

      if (matchOption) {
        setSelectedOptioncli(matchOption);

        getPlantabyCliente2(matchOption.value).then((res) => {
          if (res.data.length !== 0) {
            // Se crea el array de plantas
            const nuevasPlantas = res.data.map((item) => ({
              label: item.desc_pla,
              value: item.id,
            }));

            // Si plantaId no está vacío
            if (token.plantaId !== "") {
              // Si plantaId tiene un solo número, se configura el selectedOptionPla
              if (token.plantaId.split(",").length === 1) {
                const selectedPlant = nuevasPlantas.find(
                  (planta) => String(planta.value) === token.plantaId
                );
                //setSelectedOptionpla(selectedPlant || null); // Si no se encuentra la planta, setea null
                const auxPlantas = [selectedPlant]
                setPlantas(auxPlantas)

                // buscamos el match con los datos
                const plantaEncontrada = auxPlantas.find(
                  (p) => p.value === datos.plantaId
                );

                if (plantaEncontrada) {
                  // Si lo encontramos, seteamos esa como selectedOptionpla
                  setSelectedOptionpla(plantaEncontrada);
                } else {
                  // Si no está en la lista, seteamos una "eliminada" para que marque el campo en rojo.
                  setSelectedOptionpla({
                    label: datos.planta,
                    value: datos.plantaId,
                    estado: "eliminado",
                  });
                }

              }
              // Si plantaId tiene varios números, se filtran las plantas
              else {
                const plantaIds = token.plantaId.split(",").map(id => parseInt(id.trim(), 10));
                const filteredPlantas = nuevasPlantas.filter((planta) =>
                  plantaIds.includes(planta.value)
                );
                setPlantas(filteredPlantas);

                // buscamos el match con los datos
                const plantaEncontrada = filteredPlantas.find(
                  (p) => p.value === datos.plantaId
                );

                if (plantaEncontrada) {
                  // Si lo encontramos, seteamos esa como selectedOptionpla
                  setSelectedOptionpla(plantaEncontrada);
                } else {
                  // Si no está en la lista, seteamos una "eliminada" para que marque el campo en rojo.
                  setSelectedOptionpla({
                    label: datos.planta,
                    value: datos.plantaId,
                    estado: "eliminado",
                  });
                }
              }
            }
            // Si plantaId está vacío, solo se guardan todas las plantas
            else {
              setPlantas(nuevasPlantas);

              // buscamos el match con los datos
              const plantaEncontrada = nuevasPlantas.find(
                (p) => p.value === datos.plantaId
              );

              if (plantaEncontrada) {
                // Si lo encontramos, seteamos esa como selectedOptionpla
                setSelectedOptionpla(plantaEncontrada);
              } else {
                // Si no está en la lista, seteamos una "eliminada" para que marque el campo en rojo.
                setSelectedOptionpla({
                  label: datos.planta,
                  value: datos.plantaId,
                  estado: "eliminado",
                });
              }
            }
          }
        });
      }


    }


    if (datos && !Array.isArray(datos) && token.clienteId === "") {

      const matchOption = allCLientes.find(
        (option) => option.label === datos.cliente
      );
      if (matchOption) {
        setSelectedOptioncli(matchOption);
        /*  getPlantabyCliente2(matchOption.value).then((res) => {
            if (res.data.length !== 0) {
              setPlantas(
                res.data.map((res) => {
                  let plantaselOk="";
                  if (datos.plantaId === res.id) {
                    alert("viene por econtro");
                    //setSelectedOptionpla({ label: res.desc_pla, value: res.id });
                    plantaselOk = { label: res.desc_pla, value: res.id };
                  }else{
                    alert("eliminado mete")
                   // setSelectedOptionpla({ label: res.desc_pla, value: res.id, estado: "eliminado" });
                  }
                  if(plantaselOk!==""){
                    setSelectedOptionpla({ label: res.desc_pla, value: res.id });
                  }else{
                    setSelectedOptionpla({ label: res.desc_pla, value: res.id, estado: "eliminado" });
                  }
  
                  return { label: res.desc_pla, value: res.id };
                })
              );
            }
          });*/
        getPlantabyCliente2(matchOption.value).then((res) => {
          if (res.data.length !== 0) {
            // se crea el array de plantas
            const nuevasPlantas = res.data.map((item) => ({
              label: item.desc_pla,
              value: item.id,
            }));

            // se lo guarda completo
            setPlantas(nuevasPlantas);

            // buscamos el match con los datos
            const plantaEncontrada = nuevasPlantas.find(
              (p) => p.value === datos.plantaId
            );

            if (plantaEncontrada) {
              // Si lo encontramos, seteamos esa como selectedOptionpla
              setSelectedOptionpla(plantaEncontrada);
            } else {
              // Si no está en la lista, seteamos una "eliminada" para que marque el campo en rojo.
              setSelectedOptionpla({
                label: datos.planta,
                value: datos.plantaId,
                estado: "eliminado",
              });
            }
          }
        });

      } else {
        // if (controlPanel) {
        //console.log("vino por aca" + JSON.stringify(allCLientes));
        setSelectedOptioncli({ label: datos.cliente, value: datos.clienteId, estado: "eliminado" });
        allCLientes.push({ label: datos.cliente, value: datos.clienteId, estado: "eliminado" });
        await fetchPlantas();
        // }

      }
    }
  };

  const fetchEstados = async () => {
    setIsLoading(true);
    const res = await getEstadoOrdenesOptions();
    const unidades = res?.data?.map((item) => ({
      value: item.id,
      label: item.desc_ef_ot,
      color: item.color_ef_ot,
    }));
    setEstados(unidades);
    setIsLoading(false);
    if (datos) {
      const matchOption = unidades.find(
        (option) => option.label === datos.planta
      );
      if (matchOption) {
        setSelectedOptionest(matchOption);
      } else if (unidades.length > 0 && editar) {
        setSelectedOptionest(unidades[0]);
      }
    } else if (unidades.length > 0 && editar) {
      setSelectedOptionest(unidades[0]);
    }
  };

  const fetchOrdenTrabajoLog = async (ordenId) => {
    const res = await getAllOrdenTrabajoLog(ordenId);
    // console.log("getAllOrdenTrabajoLog: ", res);
    setOrdenTrabajoLog(
      res.data.map((item) => {
        return {
          fecha: item.fecha_estado_otl,
          usuario: item.last_modified_by,
          accion: item.desc_otl,
          estado: item.estado_new_id.accion_ef_ot,
        };
      })
    );
    setIsLoading(false);
  };

  useEffect(() => {
    fetchclientesAndPlantas();

    fetchEstados();
    if (controlPanel) {
      getMuestraContadores(form.id).then((res) => {
        const transformedData = transformApiResponse(res.data);
        setDatosRows(transformedData);
        //  cargar en un estado nuevo los datos de las muestras
      });
    }
  }, []);

  const handleChangeCliente = (e) => {
    setSelectedOptionpla(null);
    setPlantas([]);
    setForm({ type: "SET_FIELD", field: "planta", value: "" });

    setSelectedOptioncli(e);
    setForm({ type: "SET_FIELD", field: "cliente", value: e.value });

    //if (form.cliente !== "" && !ver && selectedOptioncli !== null) {
    getPlantabyCliente2(e.value).then((res) => {
      setPlantas(
        res.data.map((res) => {
          return { label: res.desc_pla, value: res.id };
        })
      );
    });
    // }
  };

  const handleChange = (e, nameReactSelect) => {
    if (e.target) {
      const { name, value, checked, type } = e.target;
      const fieldValue = type === "checkbox" ? checked : value;
      setForm({ type: "SET_FIELD", field: name, value: fieldValue });
    } else {
      const value = e.value;
      const name = nameReactSelect;

      if (name === "cliente") {
        // El cliente no se guarda, solo sirve para obtener las plantas.
        setSelectedOptionpla("");
        setSelectedOptioncli(e);
        setForm({ type: "SET_FIELD", field: name, value: value });
      } else if (name === "planta") {
        //REVISAR ACA
        setSelectedOptionpla(e);
        setForm({ type: "SET_FIELD", field: name, value: value });
      } else if (name === "estado") {
        //REVISAR ACA
        setSelectedOptionest(e);
        setForm({ type: "SET_FIELD", field: name, value: value });
      }
    }
  };

  const handleChangeCalendar = (e, nameCalendar) => {
    if (e === null) {
      setForm({ type: "SET_FIELD", field: nameCalendar, value: null });
      return;
    }
    setFechaseleccionada(e);
    const name = nameCalendar;
    const value = e.$d;
    setForm({ type: "SET_FIELD", field: name, value: value });
  };

  const cleanFormattedDate = fechaseleccionada
    ? new Intl.DateTimeFormat("es-ES", {
      month: "long",
      year: "numeric",
    })
      .format(new Date(fechaseleccionada))
      .replace(" de ", "/")
    : "";

  const titulo = () => {
    const titulo = controlPanel
      ? "Control Panel Ordenes de Trabajo"
      : editar
        ? "Editar Ordenes de Trabajo"
        : "Crear o editar Ordenes de Trabajo";
    return titulo;
  };

  const testValidacion = () => {
    const campoValido = (campo) => form[campo] !== null && form[campo] !== "";
    const camposaValidar = [
      "cliente",
      "planta",
      "fecha",
      //"codigo",
      //"lote",
      "denominacion",
    ];
    // console.log(form);
    const formularioCompleto = camposaValidar.every(campoValido);
  };

  const renderButtons = () => {
    return (
      <>
        {/* <CustomButton
          onClick={testValidacion}
          label={"Test"}
          variant="contained"
          className={`buttonColorDisabled btn`}
        /> */}

        <CustomButton
          onClick={() => props.onClick({ msj: "", status: "cancel" })}
          label={"Cancelar"}
          variant="contained"
          className={`buttonColorDisabled btn`}
        />

        <CustomButton
          label={"Confirmar"}
          variant="contained"
          className={`buttonColorDefault btn`}
          type="submit"
          onClick={(e) => {
            handleSubmit(e);
          }}
          disabled={!selectedOptioncli || !selectedOptionpla || !formCompleto || (selectedOptioncli.estado && selectedOptioncli.estado === "eliminado")}
        // disabled={!editar ? !formCompleto : false}
        />
      </>
    );
  };

  const avanzarOrden = async () => {
    setIsLoading(true);
    avanzarOrdenTrabajo(form.id).then((res) => {
      // console.log("linea 504 avanzarOrdenTrabajo: ", JSON.stringify(res));
      // console.log("linea 504 res.data.estado" + res.data.estado);

      setForm({
        type: "SET_FIELD",
        field: "estadoCompleto",
        value: res.data.estado,
      });
      setKey(key + 1);

      ///aca ttengo que poner el nuesvo estadocompleto en el formulario y prubar..
    });
  };

  const handleModalNotificar = () => {
    return (
      <Modal
        datos={form}
        isOpen={statusModal}
        type={"notiordenesdetrabajo"}
        onClose={() => {
          setModalStatus(false);
        }}
        onClickSuccess={() => {
          avanzarOrden();
          setModalStatus(false);
        }}
        modalNav={true}
        titulo={labelModalNoti}
      />
    );
  };

  const handleModalDiagMasivo = () => {
    return <Modal
      datos={{ cliente: selectedOptioncli, orden: { id: form.id, denominacion: form.denominacion } }}
      isOpen={statusModalDiagMasivo}
      type={"diagMasivo"}
      onClose={() => {
        setModalStatusDiagMasivo(false);
      }}
    />
  }
  const handleCloseModalMuestras = () => {
    // 1. Llamamos la API
    fetchContadores();
    setStatusModalMuestras(false);
    // 2. Cerramos el modal
  };
  const fetchContadores = () => {
    getMuestraContadores(form.id).then((res) => {
      const transformedData = transformApiResponse(res.data);
      setDatosRows(transformedData);
      //  cargar en un estado nuevo los datos de las muestras
    })
      .catch((error) => {
        console.error("Error al obtener contadores:", error);
      });
  };

  const handleModalMuestras = () => {
    return <Modal
      datos={{
        cliente: { label: selectedOptioncli?.label, value: selectedOptioncli?.value },
        orden: { label: form.denominacion, value: form.id },
        button: "crear"
      }}
      isOpen={statusModalMuestras}
      type={"muestras"}
      onClose={() => {
        handleCloseModalMuestras()
      }}
    />
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    let formattedData = {
      //codigo_otra: "",
      // nro_lote_otra: form.lote,
      desc_otra: form.denominacion,
      fecha_programada_otra: form.fecha,
      fecha_programada_otra_str: form.fecha.toLocaleString(),
      planta_id: form.planta,
      cliente_id: form.cliente,
      created_by: "usuario_creador",
      created_date: new Date(),
      eliminado: false,
      tenant_id: 1,
      status: "SI",
    };

    if (editar || controlPanel) {
      // console.log(JSON.stringify(formattedData));
      formattedData.id = form.id;
      updateOrdenTrabajo(formattedData)
        .then((res) => {
          setIsLoading(false);
          props.onClick({
            msj: "Orden de trabajo modificada con exito.",
            status: "success",
          });
        })
        .catch((err) => {
          setIsLoading(false);
          props.onClick({
            msj: "No se Pudo modificar la Orden de trabajo.",
            status: "error",
          });
        });
    } else {
      createOrdenTrabajo(formattedData)
        .then((res) => {
          setIsLoading(false);
          props.onClick({
            msj: "Orden de trabajo creada con exito.",
            status: "success",
          });
        })
        .catch((err) => {
          setIsLoading(false);
          props.onClick({
            msj: "No se pudo crear la Orden de trabajo.",
            status: "error",
          });
        });
    }
  };

  return (
    <StyledModalcomponentMuestras
      sx={
        controlPanel
          ? {
            width: "70%",
            "@media (max-width: 768px)": {
              width: "90%",
              overflowY: "scroll",
              height: "70%",
            },
            "@media (max-width: 1500px)": {
              width: "90%",
              height: "80%",
              overflowY: "scroll",
            },
          }
          : {
            width: "60%",
            "@media (max-width: 768px)": {
              width: "90%",
              overflowY: "scroll",
              height: "70%",
            },
            "@media (max-width: 1400px)": {
              width: "90%",
              height: "80%",
              overflowY: "scroll",
            },
          }
      }
    >
      <Preloader
        title="Cargando..."
        subtitle={"Espere unos Segundos por favor..."}
        loading={isLoading}
      />
      {handleModalDiagMasivo()}
      {handleModalMuestras()}
      <div classname="toast">
        {success ? (
          <Toast title={msjToast} close={() => setSuccess(false)} />
        ) : null}
        {error ? (
          <Toast
            type={"error"}
            title={
              "Error al " + (editar ? "modificar" : "crear") + "la orden ?"
            }
            close={() => setError(false)}
          />
        ) : null}
      </div>
      {handleModalNotificar()}

      {!controlPanel ? (
        <div className="ordenesDeTrabajo">
          <div className="divLabel">
            <p className="titulo"> {titulo()} </p>
          </div>
          <div className="divInputsItem">
            <form className="formulario" onSubmit={handleSubmit}>
              <div className="divInputsUno">
                <div style={{ width: "33%" }}>
                  <Input
                    isLoading={
                      selectedOptioncli === null && (editar || controlPanel)
                    }
                    reactSelect
                    options={clientes}
                    value={selectedOptioncli}
                    onChange={(e) => {
                      handleChangeCliente(e, "cliente");
                    }}
                    isDisabled={ver && true || token.clienteId !== ""}
                    placeholder={token.clienteId !== "" ? "Cliente" :
                      ver ? "Cliente no disponible" : "Seleccione el cliente"
                    }
                  />
                  {selectedOptioncli && selectedOptioncli.estado === "eliminado" ? <p className="p-0 m-0 " style={{ margin: "0px", fontStyle: "italic", fontWeight: "bold", color: "red" }}>**cliente eliminado, elegir otro**</p> : null}
                </div>
                <div style={{ width: "33%" }}>
                  <Input
                    isLoading={
                      selectedOptionpla === null && (editar || controlPanel)
                    }
                    reactSelect
                    options={plantas}
                    value={selectedOptionpla}
                    onChange={(e) => {
                      handleChange(e, "planta");
                    }}
                    isDisabled={
                      (token.plantaId && token.plantaId.split(",").length === 1 && token.plantaId.trim() !== "") || (datos.length === 0 && form.cliente === "") ? true : ver
                    }
                    placeholder={(token.plantaId && token.plantaId.split(",").length === 1 && token.plantaId.trim() !== "") ? "Planta" :
                      form.cliente === ""
                        ? "Seleccione un cliente primero"
                        : "Selecciona la planta"
                    }
                  />
                  {selectedOptioncli && selectedOptionpla?.estado === "eliminado" ? <p className="p-0 m-0 " style={{ margin: "0px", fontStyle: "italic", fontWeight: "bold", color: "red" }}>**planta eliminado**</p> : null}
                </div>
                <div
                  style={{
                    width: "33%",
                  }}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer
                      components={["DatePicker", "DatePicker", "DatePicker"]}
                    >
                      <DatePicker
                        className="inputDate"
                        label="Fecha programada"
                        disabled={ver}
                        // defaultValue={form}
                        value={fechaseleccionada}
                        slotProps={
                          <Input
                            className="input inputDate"
                            variant="outlined"
                            size={"medium"}
                          />
                        }
                        onChange={(e) => {
                          handleChangeCalendar(e, "fecha");
                        }}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </div>
              </div>
              <div className="divInputsDos">
                {/* <Input
                  value={form.codigo}
                  label="Código"
                  classnamediv="divInputCodigo divInput"
                  className="input inputCodigo"
                  variant="outlined"
                  size={"medium"}
                  name="codigo"
                  onChange={handleChange}
                  disabled={bloquearInput}
                  // disabled={ver && true}
                />
                <Input
                  value={form.lote}
                  label="Lote"
                  classnamediv="divInputLote divInput"
                  className="input inputLote"
                  variant="outlined"
                  size={"medium"}
                  name="lote"
                  type="number"
                  disabled={ver}
                  onChange={handleChange}
                /> */}
                <Input
                  value={form.denominacion}
                  label="Denominación"
                  classnamediv="divInputDescripcion divInput"
                  className="input inputDescripcion"
                  variant="outlined"
                  size={"medium"}
                  name="denominacion"
                  disabled={ver}
                  onChange={handleChange}
                />

              </div>
              <div className="divButtonsItem">{renderButtons()}</div>
            </form>
          </div>
        </div>
      ) : (
        <div key={key} className="ordenesDeTrabajoPanelControl">
          <Preloader
            title="Cargando..."
            subtitle={"Espere unos Segundos por favor..."}
            loading={isLoading}
          />
          <div className="divLabel">
            <p className="titulo"> Control Panel Ordenes de Trabajo </p>
          </div>
          <form className="formularioPanelControl">
            <div className="divColumnaUno">
              <div className="divHeader">
                {form.estadoCompleto.final_sn_ef_ot === "SI" ? (
                  ""
                ) : (
                  <CustomButton
                    onClick={() => {
                      setBloquearInput(!bloquearInput);
                    }}
                    startIcon={<CreateOutlinedIcon />}
                    label={"Editar OT"}
                    variant="contained"
                    className={`buttonColorTable`}
                  />
                )}
              </div>
              <div className="divInputsPanelControl">
                <div className="divUnoPanelControl">
                  <div style={{ width: "50%" }}>
                    <Input
                      value={form.codigo}
                      label="Código"
                      classnamediv=" divInput"
                      className="input "
                      variant="outlined"
                      size={"medium"}
                      name="codigo"
                      onChange={handleChange}
                      disabled={bloquearInput}
                    />
                  </div>
                  <div style={{ width: "50%" }}>
                    <Input
                      value={form.estadoCompleto?.desc_ef_ot}
                      label="Estado"
                      classnamediv=" divInput"
                      className="input "
                      variant="outlined"
                      size={"medium"}
                      name="codigo"
                      disabled={true}
                    />
                  </div>
                </div>
                <div
                  className="divDosPanelControl"
                  style={{ flexDirection: "row" }}
                >
                  <div style={{ width: "46%" }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer
                        components={["DatePicker", "DatePicker", "DatePicker"]}
                      >
                        <DatePicker
                          className="inputDate2"
                          label="Fecha programada"
                          placeholder="Fecha programada"
                          // value={form.fecha}
                          value={fechaseleccionada}
                          onChange={(e) => {
                            handleChangeCalendar(e, "fecha");
                          }}
                          disabled={bloquearInput}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </div>
                  <div style={{ width: "20%" }}>
                    <Input
                      value={form.lote}
                      label="Lote"
                      classnamediv="divInputLote divInput"
                      className="input inputLote"
                      variant="outlined"
                      size={"medium"}
                      name="lote"
                      onChange={handleChange}
                      disabled={bloquearInput}
                    />
                  </div>
                  <div style={{ width: "33%" }}>
                    <Input
                      value={form.denominacion}
                      label="Descripción"
                      classnamediv="divInputDescripcion divInput"
                      className="input inputDescripcion"
                      variant="outlined"
                      size={"medium"}
                      name="denominacion"
                      onChange={handleChange}
                      disabled={bloquearInput}
                    />
                  </div>
                </div>
                <div
                  className="divDosPanelControl"
                  style={{ flexDirection: "row" }}
                >
                  <div style={{ width: "50%" }}>
                    <Input
                      isLoading={
                        selectedOptioncli === null && (editar || controlPanel)
                      }
                      reactSelect
                      options={clientes}
                      value={selectedOptioncli}
                      onChange={(e) => {
                        handleChangeCliente(e);
                      }}
                      isDisabled={bloquearInput || token.clienteId !== ""}
                      placeholder={
                        token.clienteId !== "" ? "Cliente" : selectedOptioncli === ""
                          ? "Cliente no disponible"
                          : "Cliente"
                      }
                    />

                    {selectedOptioncli && selectedOptioncli.estado === "eliminado" ?
                      <Box sx={{
                        display: "flex",
                        gap: "10px",
                        borderRadius: "8px",
                        padding: "5px",
                        border: `3px solid ${colors.turquoise}`,
                        alignItems: "center"
                      }}>

                        <InfoIcon color="error" />
                        <Typography sx={{ fontFamily: fonts.primary, fontWeight: 500, fontSize: "14px" }}>
                          El cliente ha sido eliminado, por favor selecciona otro.
                        </Typography>
                      </Box>
                      : null}
                  </div>
                  <div style={{ width: "50%" }}>
                    <Input

                      reactSelect
                      options={plantas}
                      value={selectedOptionpla}
                      onChange={(e) => {
                        handleChange(e, "planta");
                      }}
                      // isDisabled={
                      //   bloquearInput && form.cliente === "" ? true : false
                      // }
                      isDisabled={(token.plantaId && token.plantaId.split(",").length === 1 && token.plantaId.trim() !== "") || bloquearInput}
                      placeholder={(token.plantaId && token.plantaId.split(",").length === 1 && token.plantaId.trim() !== "") ? "Planta" :
                        selectedOptionpla === ""
                          ? "Seleccione un cliente primero."
                          : "Planta"
                      }
                    />
                  </div>
                </div>
                <div className="divTresPanelControl">
                  {form.estadoCompleto &&
                    form.estadoCompleto.final_sn_ef_ot === "SI" ? (
                    ""
                  ) : (
                    <CustomButton
                      startIcon={<ForwardToInboxIcon />}
                      label={form.estadoCompleto?.accion_ef_ot}
                      variant="contained"
                      size={"small"}
                      className={`buttonColorDefault`}
                      onClick={() => {
                        setModalStatus(true);
                        //avanzarOrdenTrabajo(form.id);
                      }}
                    />
                  )}
                </div>
                <div className="divCuatroPanelControl">
                  <p className="title">Cambios de Estado</p>
                  <Tables
                    typeTable={"prop"}
                    columns={columnsDefaultCfgDos}
                    rows={ordenTrabajoLog}
                    pagination={false}
                    tableColor={false}
                  />
                </div>
              </div>
            </div>
            <div className="divColumnaDos">
              <div className="divHeaderColumnaDos">
                <div className="buttonUno">
                  {form.estadoCompleto.final_sn_ef_ot === "SI" ? (
                    ""
                  ) : (
                    <CustomButton
                      onClick={() => {
                        handleModalMuestras();
                        setStatusModalMuestras(true);
                      }}
                      startIcon={<AddIcon />}
                      label={"Crear nueva muestra"}
                      variant="contained"
                      size="small"
                      className={`buttonHeaderTable`}
                    />
                  )}
                </div>
                <div className="buttonsDos">
                  {form.estadoCompleto.final_sn_ef_ot === "SI" ? (
                    ""
                  ) : (
                    <CustomButton
                      onClick={() => {
                        handleModalDiagMasivo();
                        setModalStatusDiagMasivo(true);
                      }}
                      startIcon={<BiotechIcon />}
                      label={"Diag. Masivo"}
                      variant="contained"
                      size="small"
                      className={`buttonColorTable`}
                    />
                  )}
                  <CustomButton
                    onClick={() => {
                      descargarExcelLleno(datos.id)
                        .then((res) => {
                          const url = window.URL.createObjectURL(new Blob([res.data]));
                          const link = document.createElement("a");
                          link.href = url;
                          link.setAttribute("download", "orden_trabajo_" + datos.id + ".xlsx"); // Nombre del archivo
                          document.body.appendChild(link);
                          link.click();
                          document.body.removeChild(link);
                        })
                        .catch((error) => {
                          console.error("Error al descargar el Excel:", error);
                        });
                    }}
                    startIcon={<ArticleIcon />}
                    label={"Excel resultados"}
                    variant="contained"
                    size="small"
                    className={`buttonColorTable`}
                  />
                </div>
              </div>
              <Tables
                columns={columnsDefaultCfg}
                rows={datosRows}
                typeTable={"prop"}
                pagination={false}
                tableColor={true}
              />
            </div>
          </form>
          <div className="divButtonsItem">{renderButtons()}</div>
        </div>
      )}
    </StyledModalcomponentMuestras>
  );
};

export default OrdenesDeTrabajoModal;
