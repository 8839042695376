import React, { useState } from 'react'
import { StyledModalSuperAdmin } from '../../Styled.jsx';
import CustomButton from '../../../Button/CustomButton.tsx';
import { deleteUser } from '../../../../api/Clientes.js';
import Preloader from '../../../Preloader/Preloader.jsx';

const DeleteUsuariosSuperAdminModal = (props) => {
  const [isLoading,setIsLoading] = useState(false);

  const handleConfirmar =  () => {
    setIsLoading(true);

    deleteUser(props.datos.datos.id)
    .then((res) => {
      setIsLoading(false);
      props.onClick({
        msj: "User eliminado con exito.",
        status: "success",
      });
    })
    .catch((err) => {
        setIsLoading(false);
        props.onClick({
          msj: "No se pudo eliminar al User.",
          status: "error",
        });
    });
  }

  return (
    <StyledModalSuperAdmin
      sx={{
        width: "40%",
        "@media (max-width: 768px)": {
          width: "80%",
          height: "30%",
          overflow: "hidden"
        },
      }}
    >
      <Preloader
        title="Cargando..."
        subtitle={"Espere unos Segundos por favor..."}
        loading={isLoading}
      />
      <div className="deleteUsuariosSuperAdminModal">
        <div className="divLabel">
          <p className="titulo"> ELIMINACIÓN </p>
        </div>
        <div className="divBody">
          <p className="label">  Al eliminar el usuario, no podrá recuperarlo</p>
        </div>
        <div className="divButtons">
          <p className="label">Confirma la eliminación?</p>
          <div className="buttons">
            <CustomButton
              onClick={props.onClick}
              label={"Cancelar"}
              variant="contained"
              className={"buttonColorDisabled"}
            />
            <CustomButton
              onClick={ handleConfirmar }
              label={"Confirmar"}
              variant="contained"
              className={"buttonColorDefault"}
            />
          </div>
        </div>
      </div>
    </StyledModalSuperAdmin>
  )
}

export default DeleteUsuariosSuperAdminModal