import axios from "./axios";
//
export const createCliente = (cliente) => axios.post("/createCliente", cliente);
export const updateCliente = (cliente) => axios.put(`/updateCliente`, cliente);
export const deleteCliente = (id) => axios.delete(`/deleteCliente/${id}`);
export const getClientes = () => axios.get("/allCliente");
export const getClientesPaginated = (page, sort) =>
  axios.get(
    "/allCliente?page=" + page + "&pageSize=20&sortBy=" + sort + "&searchTerm="
  );
export const getClienteOptions = () => axios.get("/allClienteOptions");
export const getClientesSearch = (page, sort, search) =>
  axios.get(
    "/allCliente?page=" +
    page +
    "&pageSize=20&sortBy=" +
    sort +
    "&searchTerm=" +
    search
  );
//options para prefiltrar
export const getPlantaOptionsWithCliente = () => axios.get("/allPlantaOptionsWithCliente");
export const getEquipoOptionsWithCliente = () => axios.get("/allEquipoOptionsWithCliente");
// fin options para pre filtrar
export const createPlanta = (cliente) => axios.post("/createPlanta", cliente);
export const updatePlanta = (cliente) => axios.put(`/updatePlanta`, cliente);
export const deletePlanta = (id) => axios.delete(`/deletePlanta/${id}`);
export const getPlanta = () => axios.get("/allPlanta");
export const getPlantaPaginated = (page, sort) =>
  axios.get(
    "/allPlanta?page=" + page + "&pageSize=20&sortBy=" + sort + "&searchTerm="
  );
export const getPlantaSearch = (page, sort, search) =>
  axios.get(
    "/allPlanta?page=" +
    page +
    "&pageSize=20&sortBy=" +
    sort +
    "&searchTerm=" +
    search
  );
export const getPlantaByClienteOptions = (id) => axios.get("/allPlantaByClienteIdOptions/" + id);
///allPlanta/cliente/:clienteId
export const getPlantabyCliente = (id, page, sort, search) =>
  axios.get(
    "/allPlanta/cliente/" +
    id +
    "?page=" +
    page +
    "&pageSize=20&sortBy=" +
    sort +
    "&searchTerm=" +
    search
  );
export const getPlantabyCliente2 = (id) =>
  axios.get("/allPlanta/cliente/" + id + "/option");
export const getEquiposByPlantas2 = (id) =>
  axios.get("/allEquipo/planta/" + id + "/option");
export const getIntervencionByEquipo2 = (id) =>
  axios.get("/allIntervencion?equipoId=" + id + "/option");
export const getLastIntervencionesByEquipo = (idEquipo) =>
  axios.get("/lastFiveIntervencion/equipo/" + idEquipo)
export const getLastIntervencionByEquipo = (idEquipo) =>
  axios.get("/lastIntervencion/equipo/" + idEquipo);

export const getPlantaOptions = () => axios.get("/allPlantaOptions");

export const imagenesUploadZip = (base64file) => axios.post("/uploadZipArchivo", base64file);

export const createEquipos = (cliente) => axios.post("/createEquipo", cliente);
export const updateEquipos = (cliente) => axios.put(`/updateEquipo`, cliente);
export const deleteEquipos = (id) => axios.delete(`/deleteEquipo/${id}`);
export const getEquipos = () => axios.get("/allEquipo");
export const getEquiposPaginated = (page, sort) =>
  axios.get(
    "/allEquipo?page=" + page + "&pageSize=20&sortBy=" + sort + "&searchTerm="
  );
export const getEquiposSearch = (page, sort, search) =>
  axios.get(
    "/allEquipo?page=" +
    page +
    "&pageSize=20&sortBy=" +
    sort +
    "&searchTerm=" +
    search
  );
// export const getEquiposByPlantas = (id) => axios.get("/allEquipo/planta/" + id);
export const getEquiposByPlantas = (id, page, sort, search) =>
  axios.get(
    "/allEquipo/planta/" +
    id +
    "?page=" +
    page +
    "&pageSize=20&sortBy=" +
    sort +
    "&searchTerm=" +
    search
  );
export const getEquiposByPlantasOption = (id) =>
  axios.get("/allEquipo/planta/" + id + "/option");

export const getEquiposByClientes = (id, page, sort, search) =>
  axios.get(
    "/allEquipo/cliente/" +
    id +
    "?page=" +
    page +
    "&pageSize=20&sortBy=" +
    sort +
    "&searchTerm=" +
    search
  );
export const getEquiposOptions = () => axios.get("/allEquipoOptions");
export const getEquipoOptionsByCliente = (id) =>
  axios.get("/allEquipoOptions/cliente/" + id);

export const getEquipoOptionsByPlanta = (id) =>
  axios.get("/allEquipo/planta/" + id + "/option");




export const createPuntoTomas = (cliente) =>
  axios.post("/createPuntoTomas", cliente);
export const updatePuntoTomas = (cliente) =>
  axios.put(`/updatePuntoTomas`, cliente);
export const deletePuntoTomas = (id) => axios.delete(`/deletePuntoTomas/${id}`);
export const getPuntoTomas = () => axios.get("/allPuntoTomas");
export const getPuntoTomasPaginated = (page, sort, search) =>
  axios.get(
    "/allPuntoTomas?page=" +
    page +
    "&pageSize=20&sortBy=" +
    sort +
    "&searchTerm=" +
    search
  );
export const getPuntoTomasSearch = (search) =>
  axios.get("/allPuntoTomas?searchTerm=" + search);

/*
export const getPuntosTomasByPlanta = (id) =>
axios.get("/allPuntoTomas?idPlanta=" + id);
*/

export const getPuntosTomasByPlanta = (page, sort, search, id) =>
  axios.get(
    "/allPuntoTomas?page=" +
    page +
    "&pageSize=20&sortBy=" +
    sort +
    "&searchTerm=" +
    search +
    "&idPlanta=" +
    id
  );

export const getPuntosTomasByEquipo = (page, sort, search, id) =>
  axios.get(
    "/allPuntoTomas/equipo/" +
    id +
    "?page=" +
    page +
    "&pageSize=20&sortBy=" +
    sort +
    "&searchTerm=" +
    search
  );

export const getPuntosTomasByEquipoPlanta = (page, sort, search, id, idPlanta) =>
  axios.get(
    "/allPuntoTomas/equipo/" +
    id +
    "?page=" +
    page +
    "&pageSize=20&sortBy=" +
    sort +
    "&searchTerm=" +
    search +
    "&idPlanta=" +
    idPlanta
  );

export const getPuntosTomasByCliente = (id) =>
  axios.get("/allPuntoTomasByClienteIdOptions/" + id);

export const getIntervencion = () => axios.get("/allIntervencion");
export const getIntervecionPaginated = (page, sort) =>
  axios.get(
    "/allIntervencion?page=" +
    page +
    "&pageSize=20&sortBy=" +
    sort +
    "&searchTerm="
  );
export const getIntervecionSearch = (page, sort, search) =>
  axios.get(
    "/allIntervencion?page=" +
    page +
    "&pageSize=20&sortBy=" +
    sort +
    "&searchTerm=" +
    search
  );
export const createIntervencion = (cliente) =>
  axios.post("/createIntervencion", cliente);
export const updateIntervecion = (cliente) =>
  axios.put(`/updateIntervencion`, cliente);
export const deleteIntervecion = (id) =>
  axios.delete(`/deleteIntervencion/${id}`);
export const getIntervencionByEquipo = (id, page, sort, search) =>
  axios.get(
    "/allIntervencion?idEquipo=" +
    id +
    "&page=" +
    page +
    "&pageSize=20&sortBy=" +
    sort +
    "&searchTerm=" +
    search
  );
export const getIntervencionByCliente = (id, page, sort, search) =>
  axios.get(
    "/allIntervencion?idCliente=" +
    id +
    "&page=" +
    page +
    "&pageSize=20&sortBy=" +
    sort +
    "&searchTerm=" +
    search
  );
export const getIntervencionByClienteByEquipo = (
  idCli,
  idEquipo,
  page,
  sort,
  search
) =>
  axios.get(
    "/allIntervencion?idCliente=" +
    idCli +
    "&idEquipo=" +
    idEquipo +
    "&page=" +
    page +
    "&pageSize=20&sortBy=" +
    sort +
    "&searchTerm=" +
    search
  );

export const getIntervencionWithFilters = (page, sort, search, idCliente, idPlanta, idEquipo) =>
  axios.get(
    "/allIntervencion?idCliente=" + idCliente
    + "&idPlanta=" + idPlanta
    + "&idEquipo=" + idEquipo
    + "&page=" + page
    + "&pageSize=20&sortBy=" + sort
    + "&searchTerm=" + search
  );

export const getProveedores = () => axios.get("/allProveedor");
export const getProveedorePaginated = (page, sort, search) =>
  axios.get(
    "/allProveedor?page=" +
    page +
    "&pageSize=20&sortBy=" +
    sort +
    "&searchTerm=" +
    search
  );
export const createProveedores = (cliente) =>
  axios.post("/createProveedor", cliente);
export const updateProveedores = (cliente) =>
  axios.put(`/updateProveedor`, cliente);
export const deleteProveedor = (id) => axios.delete(`/deleteProveedor/${id}`);

export const lookupDiagnostico = (idMuestra) =>
  axios.get("/lookupDiagnostico/" + idMuestra);

export const imprimirResumen = (body) => {
  return axios.post("/muestras/pdf/resumen", body, { responseType: "blob" }); // Convertir la respuesta directamente a Blob
};

export const imprimirMuestras = (body) => {
  return axios.post("/muestras/pdf", body, { responseType: "blob" }); // Convertir la respuesta directamente a Blob
};

export const descargarExcelVacio = (id) => {
  return axios.get("/excelVacio/ordenTrabajo/" + id, {
    responseType: "blob",  // IMPORTANTE: Recibir datos binarios
  });
}

export const descargarExcelLleno = (id) => {
  return axios.get("/excel/ordenTrabajo/" + id, {
    responseType: "blob",  // IMPORTANTE: Recibir datos binarios
  });
}

export const importarExcel = (body) => {
  return axios.post("/api/excel/importar", body, {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  }); // Convertir la respuesta directamente a Blob
};

export const lookupTenant = (id) =>
  axios.get("/lookupTenant/" + id);

export const updateTenant = (body) =>
  axios.put("/updateTenant", body);

// export const allTenant = () => axios.get("/allTenant", {
//   headers: {
//     Authorization: "basic eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE3MTQ1OTE4ODMsCiAgImlkIiA6ICIxIiwKICAidGVuYW50SWQiIDogIjEiLAogICJyb2wiIDogIlNVUEVSQURNSU4iCn0.hEIcL76C3j5R2Iqd-JA799imf4y0_qZ9w1BLWOIPr04"
//   }
// });

// export const createTenant = (tenant) => axios.post("/createTenant", tenant,{
//   headers: {
//     Authorization: "basic eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE3MTQ2NjY0ODYsCiAgImlkIiA6ICIxIiwKICAidGVuYW50SWQiIDogIjEiLAogICJyb2wiIDogIlNVUEVSQURNSU4iCn0.qSW-KteqqPOZO4nLQ7WrLkh0D8IMJj2RpLiKCNckphg"
//   }
// });

export const getAllUsers = (page, sort, search) =>
  axios.get(
    "/allUser?page=" +
    page +
    "&pageSize=20&sortBy=" +
    sort +
    "&searchTerm=" +
    search
  );

export const updateUser = (body) =>
  axios.put("/updateUser", body);

export const createUser = (body) =>
  axios.post("/createUser", body);

export const deleteUser = (id) =>
  axios.delete("/deleteUser/" + id);

export const getAllReportes = (page, sort, search) =>
  axios.get("/allReporte?page=" +
    page +
    "&pageSize=20&sortBy=" +
    sort +
    "&searchTerm=" +
    search
  );